define("discourse/plugins/discourse-global-communities/discourse/initializers/linkify-station-code-user-field", ["exports", "discourse/lib/decorators", "discourse/lib/plugin-api"], function (_exports, _decorators, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "linkify-station-code-user-field",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        api.modifyClass("controller:user", dt7948.p({
          pluginId: "agc-base-component linkify-station-code-user-field",
          publicUserFieldsLinkified(publicUserFields) {
            return publicUserFields?.map(uf => {
              if (uf.field.dasherized_name === "station") {
                let url = `/members?station=${uf.value}`;
                uf.field.set("linkUrl", url);
              }
              return uf;
            });
          }
        }, [["method", "publicUserFieldsLinkified", [(0, _decorators.default)("publicUserFields")]]]));
      });
    }
  };
});