define("discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/will-destroy", "@ember/runloop", "@ember/service", "jquery", "discourse/lib/debounce", "discourse/lib/decorators", "discourse/lib/later", "discourse/plugins/discourse-global-communities/discourse/config/carousel-v2-config", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator", "discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/pagination", "discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/topic-tile", "discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/tv-tile", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _didUpdate, _willDestroy, _runloop, _service, _jquery, _debounce, _decorators, _later, _carouselV2Config, _analyticsAnnotator, _pagination, _topicTile, _tvTile, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TILE_COMPONENTS = {
    topic: _topicTile.default,
    tv: _tvTile.default
  };
  class CarouselV2 extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "tilesPerPage", [_tracking.tracked], function () {
      return this._calculateTilesPerPage();
    }))();
    #tilesPerPage = (() => (dt7948.i(this, "tilesPerPage"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "page", [_tracking.tracked], function () {
      return 0;
    }))();
    #page = (() => (dt7948.i(this, "page"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "isScrolling", [_tracking.tracked], function () {
      return false;
    }))();
    #isScrolling = (() => (dt7948.i(this, "isScrolling"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "tilesContainer", [_tracking.tracked], function () {
      return null;
    }))();
    #tilesContainer = (() => (dt7948.i(this, "tilesContainer"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "tiles", [_tracking.tracked], function () {
      return null;
    }))();
    #tiles = (() => (dt7948.i(this, "tiles"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "tileSpacing", [_tracking.tracked], function () {
      return null;
    }))();
    #tileSpacing = (() => (dt7948.i(this, "tileSpacing"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "tileWidth", [_tracking.tracked], function () {
      return null;
    }))();
    #tileWidth = (() => (dt7948.i(this, "tileWidth"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "touchStart", [_tracking.tracked], function () {
      return 0;
    }))();
    #touchStart = (() => (dt7948.i(this, "touchStart"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "touchEnd", [_tracking.tracked], function () {
      return 0;
    }))();
    #touchEnd = (() => (dt7948.i(this, "touchEnd"), void 0))();
    #carouselWrapperElement = null;
    get tileComponent() {
      return TILE_COMPONENTS[this.args.tileType];
    }
    get containerWidth() {
      return this.tilesContainer.offsetWidth;
    }
    get shouldDisplayPagination() {
      return !this.capabilities.touch && this.args.items?.length && this.args.items?.length > this.tilesPerPage;
    }
    get shouldDisplayPreviousButton() {
      return this.shouldDisplayPagination && this.page > 0;
    }
    get shouldDisplayNextButton() {
      if (!this.shouldDisplayPagination) {
        return false;
      }
      // in order to display the next button properly we need to calculate what is the lst slide that is fully visible in
      // the current page. we need to take into account the carouselV2Config.startPagewithNewTile flag. if it is true then
      // we need to take into account that the navigation will be performed by a round number of tiles. we do that by
      // rounding down the number of tiles per page and multiplying it by the current page number. if the flag is false
      // we simply multiply the number of tiles per page by the current page number.
      // PLEASE NOTE: this calculation will produce the index of last using a 1 based index. Since we are going to compare
      // with the size of the items array, this simplifies the comparison as we don't need to subtract 1 from the index,
      // nor the length of the items array.
      const lastVisibleItemIndex = Math.min(this.args.items?.length, _carouselV2Config.carouselV2Config.startPagewithNewTile ? Math.floor(this.tilesPerPage) * (this.page + 1) : Math.floor(this.tilesPerPage * (this.page + 1)));
      return this.args.items?.length - lastVisibleItemIndex > 0;
    }
    getTopicLabel(topic) {
      return topic.tileLabel.replace("agc.while_you_were_away.labels.", "");
    }
    updateDom(element) {
      if (element) {
        // the function can be called without a reference to the wrapping element
        this.#carouselWrapperElement = element;
      }
      // Skip if a reference to the wrapper element is not present
      if (!this.#carouselWrapperElement) {
        return;
      }
      // the dom only needs to be updated if there are items to display
      if (!this.args.items?.length) {
        return;
      }
      this.tilesContainer = this.#carouselWrapperElement.querySelector(".tiles-wrapper");
      this.tiles = this.tilesContainer?.querySelectorAll(".tile");
      this.tilesPerPage = this._calculateTilesPerPage();
      this.tileSpacing = this._calculateTileSpacing();
      this.tileWidth = this._calculateTileWidth();
      if (this.capabilities.touch && !this.#carouselWrapperElement.querySelector(".buffer")) {
        // Issue: the mobile layout includes visual horizontal buffers. The last element's margin
        // in a flex layout with '-webkit-overflow-scrolling: touch', however, is ignored.
        // Workaround: add an empty, buffer tile at the end.
        const bufferTile = '<a class="buffer tile" />';
        this.tiles[0].parentNode.insertAdjacentHTML("beforeend", bufferTile);
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "updateDom", [_decorators.bind]))();
    registerListeners(element) {
      // Recalculate widths and tilesPerPage on resize
      window.addEventListener("resize", this._handleResize);
      // mobile scroll events
      if (this.site.mobileView) {
        element.addEventListener("touchstart", this.touchStartSwipe, false);
        element.addEventListener("touchend", this.touchEndSwipe, false);
      }
    }
    static #_14 = (() => dt7948.n(this.prototype, "registerListeners", [_decorators.bind]))();
    unregisterListeners(element) {
      document.removeEventListener("resize", this._handleResize);
      // mobile scroll events
      if (this.site.mobileView) {
        element.removeEventListener("touchstart", this.touchStartSwipe, false);
        element.removeEventListener("touchend", this.touchEndSwipe, false);
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "unregisterListeners", [_decorators.bind]))();
    trackCarouselLoadedAnalytics() {
      if (!this.args.registerAnalytics) {
        return;
      }
      (0, _runloop.schedule)("afterRender", this, () => {
        // Timeout is set to 500ms so that Discourse analytics plugin can load before this event is triggered
        (0, _later.default)(() => {
          (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_loaded", this.appEvents);
        }, 500);
      });
    }
    static #_16 = (() => dt7948.n(this.prototype, "trackCarouselLoadedAnalytics", [_decorators.bind]))();
    touchStartSwipe(e) {
      this.touchStart = e.changedTouches[0].screenX;
    }
    static #_17 = (() => dt7948.n(this.prototype, "touchStartSwipe", [_decorators.bind]))();
    touchEndSwipe(e) {
      this.touchEnd = e.changedTouches[0].screenX;
      this.handleGesture();
    }
    static #_18 = (() => dt7948.n(this.prototype, "touchEndSwipe", [_decorators.bind]))();
    handleGesture() {
      if (!this.args.registerAnalytics) {
        return;
      }
      if (Math.abs(this.touchEnd) < Math.abs(this.touchStart)) {
        (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_next", this.appEvents);
      } else {
        (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_previous", this.appEvents);
      }
    }
    static #_19 = (() => dt7948.n(this.prototype, "handleGesture", [_decorators.bind]))();
    _calculateTilesPerPage() {
      if (this.tiles?.length > 1) {
        return this.args.tilesPerPage || _carouselV2Config.carouselV2Config.defaultTilesPerPage;
      } else {
        return 1;
      }
    }
    static #_20 = (() => dt7948.n(this.prototype, "_calculateTilesPerPage", [_decorators.bind]))();
    _calculateTileSpacing() {
      if (this.tileSpacing) {
        return this.tileSpacing;
      }
      if (this.tileWidth) {
        return this.tiles[0].offsetWidth - this.tileWidth;
      } else {
        return _carouselV2Config.carouselV2Config.defaultTileSpacing;
      }
    }
    static #_21 = (() => dt7948.n(this.prototype, "_calculateTileSpacing", [_decorators.bind]))();
    _calculateTileWidth() {
      const calculatedTileSize = (this.tilesContainer.offsetWidth + this.tileSpacing) / this.tilesPerPage - this.tileSpacing;
      if (!this.capabilities.touch) {
        this.tiles.forEach(tile => {
          tile.style.width = calculatedTileSize + "px";
        });
      }
      return calculatedTileSize;
    }
    static #_22 = (() => dt7948.n(this.prototype, "_calculateTileWidth", [_decorators.bind]))();
    _handleResize() {
      if (this.capabilities.touch) {
        return;
      }
      this.tileWidth = this._calculateTileWidth();
      if (this.page > 0) {
        (0, _debounce.default)(this, this._goToPage, this.page, _carouselV2Config.carouselV2Config.debounceDelay);
      }
    }
    static #_23 = (() => dt7948.n(this.prototype, "_handleResize", [_decorators.bind]))();
    _goToPage(page) {
      const outerTileWidth = this.tileWidth + this.tileSpacing;
      let tilesOnThisPage = Math.floor(this.tilesPerPage);
      if (_carouselV2Config.carouselV2Config.startPagewithNewTile === false) {
        const pageCount = (this.tiles.length / this.tilesPerPage).toFixed(2);
        tilesOnThisPage = page === pageCount ? this.tiles.length % this.tilesPerPage : this.tilesPerPage;
      }
      // offset of the beginning of first item on the page
      const pageFirstItemStartOffset = page * tilesOnThisPage * outerTileWidth;
      // offset of the beginning of last item on the page
      const pageLastItemOffset = pageFirstItemStartOffset + (tilesOnThisPage - 1) * outerTileWidth;
      const newTileContainerOffset = _carouselV2Config.carouselV2Config.startPagewithNewTile ? pageFirstItemStartOffset :
      // remains within the bounds of the first and last item on the page. it needs to be bigger
      // offset and smaller than the last item offset
      Math.min(Math.max(pageFirstItemStartOffset, this.tilesContainer.scrollLeft), pageLastItemOffset);
      if (newTileContainerOffset === this.tilesContainer.scrollLeft) {
        this.isScrolling = false;
        // there is no need to scroll if the new offset is the same as the current offset
        return;
      }
      // animate the scrolling to new offset position
      (0, _jquery.default)(this.tilesContainer).animate({
        scrollLeft: newTileContainerOffset
      }, _carouselV2Config.carouselV2Config.paginationAnimationDuration, () => {
        this.isScrolling = false;
      });
    }
    static #_24 = (() => dt7948.n(this.prototype, "_goToPage", [_decorators.bind]))();
    _shiftPages(number, analyticsEventName) {
      if (!this.args.items || this.capabilities.touch || this.isScrolling) {
        return;
      }
      this.isScrolling = true;
      this.page += number;
      this._goToPage(this.page);
      let position = this.page;
      let topic = this.args.items[position];
      if (this.args.registerAnalytics) {
        (0, _analyticsAnnotator.triggerCarouselAnalytics)(analyticsEventName, this.appEvents, {
          topic_id: topic.id,
          position,
          label: this.getTopicLabel(topic)
        });
      }
    }
    static #_25 = (() => dt7948.n(this.prototype, "_shiftPages", [_decorators.bind]))();
    nextPage() {
      this._shiftPages(1, "wywa_carousel_next");
    }
    static #_26 = (() => dt7948.n(this.prototype, "nextPage", [_object.action]))();
    previousPage() {
      this._shiftPages(-1, "wywa_carousel_previous");
    }
    static #_27 = (() => dt7948.n(this.prototype, "previousPage", [_object.action]))();
    static #_28 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="carousel-v2 carousel-v2-glimmer"
          {{didInsert this.updateDom}}
          {{didInsert this.registerListeners}}
          {{didInsert this.trackCarouselLoadedAnalytics}}
          {{didUpdate this.updateDom @items @titeType}}
          {{willDestroy this.unregisterListeners}}
        >
          <div class="carousel-container">
            {{#if this.shouldDisplayPreviousButton}}
              <PaginationButton class="previous" @action={{this.previousPage}} />
            {{/if}}
            <div class="tiles-wrapper">
              <div class="tiles">
                {{#each @items as |item index|}}
                  <this.tileComponent
                    class="tile"
                    @topic={{item}}
                    @index={{index}}
                  />
                {{/each}}
              </div>
            </div>
            {{#if this.shouldDisplayNextButton}}
              <PaginationButton class="next" @action={{this.nextPage}} />
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "wp407V5P",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"carousel-v2 carousel-v2-glimmer\"],[4,[32,0],[[30,0,[\"updateDom\"]]],null],[4,[32,0],[[30,0,[\"registerListeners\"]]],null],[4,[32,0],[[30,0,[\"trackCarouselLoadedAnalytics\"]]],null],[4,[32,1],[[30,0,[\"updateDom\"]],[30,1],[30,2]],null],[4,[32,2],[[30,0,[\"unregisterListeners\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"carousel-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"shouldDisplayPreviousButton\"]],[[[1,\"          \"],[8,[32,3],[[24,0,\"previous\"]],[[\"@action\"],[[30,0,[\"previousPage\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,0],[14,0,\"tiles-wrapper\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"tiles\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"              \"],[8,[30,0,[\"tileComponent\"]],[[24,0,\"tile\"]],[[\"@topic\",\"@index\"],[[30,3],[30,4]]],null],[1,\"\\n\"]],[3,4]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"shouldDisplayNextButton\"]],[[[1,\"          \"],[8,[32,3],[[24,0,\"next\"]],[[\"@action\"],[[30,0,[\"nextPage\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@items\",\"@titeType\",\"item\",\"index\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/index.js",
      "scope": () => [_didInsert.default, _didUpdate.default, _willDestroy.default, _pagination.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CarouselV2;
});