define("discourse/plugins/discourse-global-communities/discourse/components/post-selector", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_SELECTED_POSTS_LIMIT = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.renderPostSelector}}
    <input
      {{on "click" this.togglePostSelector}}
      checked={{this.isSelected}}
      type="checkbox"
      class="post-selector"
      id="post-selector-{{@postId}}"
    />
  {{/if}}
  */
  {
    "id": "Z07q+CtB",
    "block": "[[[41,[30,0,[\"renderPostSelector\"]],[[[1,\"  \"],[11,\"input\"],[16,\"checked\",[30,0,[\"isSelected\"]]],[24,0,\"post-selector\"],[16,1,[29,[\"post-selector-\",[30,1]]]],[24,4,\"checkbox\"],[4,[38,2],[\"click\",[30,0,[\"togglePostSelector\"]]],null],[12],[13],[1,\"\\n\"]],[]],null]],[\"@postId\"],false,[\"if\",\"input\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/post-selector.hbs",
    "isStrictMode": false
  });
  const MAX_SELECTED_POSTS_LIMIT = _exports.MAX_SELECTED_POSTS_LIMIT = 500;
  class PostSelector extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "agcPostBulkActions", [_service.service]))();
    #agcPostBulkActions = (() => (dt7948.i(this, "agcPostBulkActions"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "renderPostSelector", [_tracking.tracked]))();
    #renderPostSelector = (() => (dt7948.i(this, "renderPostSelector"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isSelected", [_tracking.tracked], function () {
      return false;
    }))();
    #isSelected = (() => (dt7948.i(this, "isSelected"), void 0))();
    constructor() {
      super(...arguments);
      this.renderPostSelector = this.agcPostBulkActions.renderPostSelector;
      this.postId = this.args.postId;

      // The post could be selected before loaded with the "Select All" button
      if (this.agcPostBulkActions.getSelectedPostIds().has(this.postId)) {
        this.isSelected = true;
        this.hightlightPostCard();
      }
      this.appEvents.on("agc-custom:bulk-post-actions-toggle-render-post-selector", this, this.toggleRenderPostSelector);
      this.appEvents.on(`agc-custom:bulk-post-actions-select-${this.postId}`, this, this.handleSelect);
      this.appEvents.on("agc-custom:bulk-post-actions-clear-selections", this, this.handleClearAll);
      this.appEvents.on(`agc-custom:toggle-post-selector-${this.postId}`, this, this.togglePostSelector);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("agc-custom:bulk-post-actions-toggle-render-post-selector", this, this.toggleRenderPostSelector);
      this.appEvents.off(`agc-custom:bulk-post-actions-select-${this.postId}`, this, this.handleSelect);
      this.appEvents.off("agc-custom:bulk-post-actions-clear-selections", this, this.handleClearAll);
      this.appEvents.off(`agc-custom:toggle-post-selector-${this.postId}`, this, this.togglePostSelector);
    }
    handleSelect() {
      if (!this.isSelected) {
        this.togglePostSelector();
      }
    }
    handleClearAll() {
      if (this.isSelected) {
        this.togglePostSelector();
      }
    }
    toggleRenderPostSelector() {
      this.renderPostSelector = !this.renderPostSelector;
    }
    togglePostSelector(event) {
      if (!this.isSelected) {
        const selectedPostsCount = document.querySelectorAll(".topic-post.selected").length;
        if (selectedPostsCount + 1 > MAX_SELECTED_POSTS_LIMIT) {
          this.dialog.alert((0, _discourseI18n.i18n)("posts.select.warning", {
            limit: MAX_SELECTED_POSTS_LIMIT
          }));
          if (event) {
            event.preventDefault();
          }
          return;
        }
      }
      this.isSelected = !this.isSelected;
      if (this.isSelected) {
        this.appEvents.trigger("agc-custom:bulk-post-actions-select-post", {
          postId: this.postId
        });
        this.hightlightPostCard();
      } else {
        this.appEvents.trigger("agc-custom:bulk-post-actions-deselect-post", {
          postId: this.postId
        });
        this.unhighlightPostCard();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "togglePostSelector", [_object.action]))();
    hightlightPostCard() {
      document.querySelector(`.topic-post article[data-post-id="${this.postId}"]`).closest(".topic-post").classList.add("selected");
    }
    unhighlightPostCard() {
      document.querySelector(`.topic-post article[data-post-id="${this.postId}"]`).closest(".topic-post").classList.remove("selected");
    }
  }
  _exports.default = PostSelector;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostSelector);
});