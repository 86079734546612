define("discourse/plugins/discourse-global-communities/discourse/components/agc-custom-button", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type="button"
    class="agc-button {{@type}} {{@class}}"
    {{on "click" @onClick}}
  >
    {{@label}}
  </button>
  */
  {
    "id": "DyA3yDVk",
    "block": "[[[11,\"button\"],[16,0,[29,[\"agc-button \",[30,1],\" \",[30,2]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[1,[30,4]],[1,\"\\n\"],[13]],[\"@type\",\"@class\",\"@onClick\",\"@label\"],false,[\"button\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc-custom-button.hbs",
    "isStrictMode": false
  });
  const AgcCustomButton = dt7948.c(class AgcCustomButton extends _component.default {}, [(0, _component2.classNames)("agc-custom-button")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcCustomButton);
});