define("discourse/plugins/discourse-global-communities/discourse/components/gc-input-with-icon", ["exports", "discourse/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="input-with-icon">
      {{icon @icon}}
      {{yield}}
    </div>
  
  */
  {
    "id": "HjqW1i1w",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"input-with-icon\"],[12],[1,\"\\n    \"],[1,[28,[32,0],[[30,1]],null]],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@icon\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-input-with-icon.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "gc-input-with-icon"));
});