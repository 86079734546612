define("discourse/plugins/discourse-global-communities/discourse/feature-flags/enhanced-notifications", ["exports", "@ember/template", "discourse/helpers/replace-emoji", "discourse/lib/get-url", "discourse/lib/url", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/lib/agc-notification"], function (_exports, _template, _replaceEmoji, _getUrl, _url, _discourseI18n, _agcNotification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const notificationTypeKey = notification => {
    let typeKey = "reply";
    if (notification.post_number === 1) {
      typeKey = "post";
    } else if (!notification.in_reply_to_post_number) {
      typeKey = "comment";
    }
    return typeKey;
  };
  class EnhancedNotificationsFeatureFlag {
    static enabledKey = "enhanced_notifications_enabled";
    static className = "agc-enhanced-notifications";
    static afterEnabled(api) {
      const controller = api.container.lookup("controller:user-notifications");
      api.addBeforeLoadMoreNotificationsCallback(() => {
        // Set arbitrary variable on the user notifications controller to gate infinite scroll
        // until a button has been pressed
        return controller.loadMorePressed;
      });
      api.onPageChange(() => {
        // Reset that variable, so that when going back, the load more button is present again
        controller.loadMorePressed = false;
      });
      api.setNotificationsLimit(20);
      api.setUserMenuNotificationsLimit(5);

      // Merged topics enhanced notification
      const siteSettings = api.container.lookup("service:site-settings");
      if (siteSettings.agc_merged_topics_enabled) {
        api.registerNotificationTypeRenderer("moved_post", () => {
          return class extends _agcNotification.default {
            get label() {
              if (this.notification.moved_from) {
                let typeKey = "reply";
                if (this.notification.moved_from.old_post_number === 1) {
                  typeKey = "post";
                } else if (!this.notification.moved_from.is_reply) {
                  typeKey = "comment";
                }
                const key = `notifications.labels.moved_post.with_moved_post_record.${typeKey}`;
                return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, {
                  username: this.displayUsername,
                  oldTopicTitle: (0, _replaceEmoji.default)(this.notification.moved_from.old_topic_title),
                  newTopicTitle: this.notification.fancy_title
                }));
              }
              return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("notifications.labels.moved_post.without_moved_post_record", {
                username: this.displayUsername,
                newTopicTitle: this.notification.fancy_title
              }));
            }
          };
        });
      }
      api.registerNotificationTypeRenderer("mentioned", () => {
        return class extends _agcNotification.default {
          get label() {
            const key = `notifications.labels.mentioned.${notificationTypeKey(this.notification)}`;
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, {
              username: this.displayUsername,
              topicTitle: this.notification.fancy_title
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("quoted", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("notifications.labels.quoted", {
              username: this.displayUsername,
              topicTitle: this.notification.data.topic_title
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("replied", () => {
        return class extends _agcNotification.default {
          get label() {
            let key;
            if (this.notification.data.original_username !== this.notification.data.display_username) {
              key = this.notification.is_topic_owner ? "notifications.labels.replied_consolidated.post_owner" : "notifications.labels.replied_consolidated.not_post_owner";
              return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, {
                replyCount: this.notification.data.display_username.split(" ")[0],
                topicTitle: this.notification.data.topic_title
              }));
            } else {
              key = this.notification.is_topic_owner ? "notifications.labels.replied.post_owner" : "notifications.labels.replied.not_post_owner";
              return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, {
                username: this.displayUsername,
                topicTitle: this.notification.data.topic_title
              }));
            }
          }
        };
      });
      api.registerNotificationTypeRenderer("edited", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("notifications.labels.edited", {
              username: this.displayUsername,
              topicTitle: this.notification.fancy_title
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("liked", () => {
        return class extends _agcNotification.default {
          get label() {
            let countKey = this.notification.data.count > 1 ? "multi" : "single";
            let key = `notifications.labels.liked.${countKey}.${notificationTypeKey(this.notification)}`;
            let args = {
              username: this.displayUsername,
              topicTitle: this.notification.fancy_title
            };
            if (countKey === "multi") {
              args.count = this.notification.data.count;
            }
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, args));
          }
          get classNames() {
            let baseClassNames = super.classNames;
            if (this.notification.data.count > 1) {
              baseClassNames.push("add-agc-like-icon");
            }
            return baseClassNames;
          }
        };
      });
      api.registerNotificationTypeRenderer("private_message", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)(`${(0, _discourseI18n.i18n)("notifications.labels.private_message")} <b>${this.displayUsername}</b>: ${this.notification.fancy_title}`);
          }
        };
      });
      api.registerNotificationTypeRenderer("invitee_accepted", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("notifications.labels.invitee_accepted", {
              username: this.displayUsername
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("posted", () => {
        return class extends _agcNotification.default {
          get label() {
            const translationArgs = {
              topicTitle: this.notification.data.topic_title
            };
            let key;
            if (this.notification.data?.display_username.includes("replies")) {
              key = "notifications.labels.posted.multiple";
              // Set username to the `3` in `3 replies` so we can rename to `3 comments`
              translationArgs.username = this.notification.data.display_username.split(" ")[0];
            } else {
              key = "notifications.labels.posted.single";
              translationArgs.username = this.displayUsername;
            }
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, translationArgs));
          }
        };
      });
      api.registerNotificationTypeRenderer("linked", () => {
        return class extends _agcNotification.default {
          get label() {
            const key = `notifications.labels.linked.${notificationTypeKey(this.notification)}`;
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, {
              username: this.displayUsername,
              topicTitle: this.notification.data.topic_title
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("granted_badge", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)(`${(0, _discourseI18n.i18n)("notifications.labels.granted_badge")} <b>${this.notification.data.badge_name}</b>`);
          }
        };
      });
      api.registerNotificationTypeRenderer("group_mentioned", () => {
        return class extends _agcNotification.default {
          get label() {
            const key = `notifications.labels.group_mentioned.${notificationTypeKey(this.notification)}`;
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)(key, {
              username: this.displayUsername,
              groupName: this.notification.data.group_name,
              topicTitle: this.notification.data.topic_title
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("group_message_summary", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("notifications.labels.group_message_summary", {
              inboxCount: this.notification.data.inbox_count
            }));
          }
          get linkHref() {
            return (0, _url.userPath)(`${this.notification.data.username}/messages/group/${this.notification.data.group_name}`);
          }
        };
      });
      api.registerNotificationTypeRenderer("liked_consolidated", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("notifications.labels.liked_consolidated", {
              username: this.displayUsername,
              count: this.notification.data.count
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("membership_request_accepted", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("notifications.labels.membership_request_accepted", {
              groupName: this.notification.data.group_name
            }));
          }
        };
      });
      api.registerNotificationTypeRenderer("bookmark_reminder", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _template.htmlSafe)(`${(0, _discourseI18n.i18n)("notifications.labels.bookmark_reminder")} <b>${this.notification.fancy_title}</b>`);
          }
        };
      });
      api.registerNotificationTypeRenderer("new_features", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _discourseI18n.i18n)("notifications.labels.new_features");
          }
          get linkHref() {
            return (0, _getUrl.default)("/admin/dashboard/whats-new");
          }
        };
      });
      api.registerNotificationTypeRenderer("admin_problems", () => {
        return class extends _agcNotification.default {
          get label() {
            return (0, _discourseI18n.i18n)("notifications.labels.admin_problems");
          }
          get linkHref() {
            return (0, _getUrl.default)("/admin");
          }
        };
      });
      const appEvents = api.container.lookup("service:app-events");
      // Listen for both browser Notification API and Web Push API notification clicks
      // and forward to analytics collector
      ["desktop-notification-opened", "push-notification-opened"].forEach(appEventName => {
        appEvents.on(appEventName, event => {
          appEvents.trigger("agc-custom:browser-notification-opened", {
            destination_url: event.url
          });
        });
      });

      // Add window listener for react-native app firing push notification opened events
      // and fire appEvents for analytics collector
      const capabilities = api.container.lookup("service:capabilities");
      if (capabilities.isAppWebview) {
        window.addEventListener("AppPushNotificationOpened", event => {
          appEvents.trigger("agc-custom:push-notification-opened", {
            destination_url: event.detail.url
          });
        });
      }
    }
  }
  _exports.default = EnhancedNotificationsFeatureFlag;
});