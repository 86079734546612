define("discourse/plugins/discourse-global-communities/discourse/feature-flags/hot-home-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HotHomeFeed {
    static enabledKey = "hot_home_feed_enabled";
  }
  _exports.default = HotHomeFeed;
});