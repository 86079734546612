define("discourse/plugins/discourse-global-communities/discourse/components/program-pillars/side-widget-topic-link", ["exports", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "discourse/helpers/topic-link", "discourse/lib/decorators", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/post-badges", "@ember/component", "@ember/template-factory"], function (_exports, _component, _didInsert, _topicLink, _decorators, _postBadges, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LoadMoreButton extends _component.default {
    decorateTopicLinkWithAnalyticsDataAttr(element) {
      const topicLinkEl = element.querySelector("a");
      if (topicLinkEl) {
        topicLinkEl.dataset.agcAnalyticsRef = this.args.tagName;
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "decorateTopicLinkWithAnalyticsDataAttr", [_decorators.bind]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="program-pillars-side-widget links link"
          {{didInsert this.decorateTopicLinkWithAnalyticsDataAttr}}
        >
          {{topicLink @topic}}
          <AgcTopicListPostBadges
            @unreadPosts={{@topic.unread_posts}}
            @unseen={{@topic.unseen}}
            @url={{@topic.lastUnreadUrl}}
          />
        </div>
      
    */
    {
      "id": "5TTtVlSX",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"program-pillars-side-widget links link\"],[4,[32,0],[[30,0,[\"decorateTopicLinkWithAnalyticsDataAttr\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,1],[[30,1]],null]],[1,\"\\n      \"],[8,[32,2],null,[[\"@unreadPosts\",\"@unseen\",\"@url\"],[[30,1,[\"unread_posts\"]],[30,1,[\"unseen\"]],[30,1,[\"lastUnreadUrl\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/program-pillars/side-widget-topic-link.js",
      "scope": () => [_didInsert.default, _topicLink.default, _postBadges.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LoadMoreButton;
});