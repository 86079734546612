define("discourse/plugins/discourse-global-communities/discourse/connectors/user-notifications-after-filter/agc-extra-controls", ["exports", "@glimmer/component", "@ember/modifier", "@ember/routing", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _routing, _ajax, _ajaxError, _decorators, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcUserNotificationsExtraControls extends _component.default {
    static shouldRender(args, container) {
      return container.currentUser.agcFeatureFlags.enhanced_notifications_enabled;
    }
    async markAllAsRead(event) {
      event.preventDefault();
      try {
        await (0, _ajax.ajax)("/notification-actions/mark-all-as-read", {
          method: "PUT"
        });
        this.args.outletArgs.items.forEach(i => {
          if (!i.notification.read) {
            i.notification.read = true;
          }
        });
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "markAllAsRead", [_decorators.bind]))();
    get hasUnread() {
      return this.args.outletArgs.items.some(item => !item.notification.read);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="agc-user-notifications-extra-controls">
          {{#if this.hasUnread}}
            <a
              role="button"
              {{on "click" this.markAllAsRead}}
              class="agc-user-notifications-extra-controls__mark-all-as-read"
            >
              {{i18n "notifications.actions.mark_all_as_read"}}
            </a>
          {{/if}}
    
          <LinkTo
            @route="preferences.notifications"
            class="agc-user-notifications-extra-controls__go-to-preferences"
          >
            {{i18n "notifications.actions.settings"}}
          </LinkTo>
        </div>
      
    */
    {
      "id": "iPcOy849",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"agc-user-notifications-extra-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasUnread\"]],[[[1,\"        \"],[11,3],[24,\"role\",\"button\"],[24,0,\"agc-user-notifications-extra-controls__mark-all-as-read\"],[4,[32,0],[\"click\",[30,0,[\"markAllAsRead\"]]],null],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"notifications.actions.mark_all_as_read\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"agc-user-notifications-extra-controls__go-to-preferences\"]],[[\"@route\"],[\"preferences.notifications\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"notifications.actions.settings\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/user-notifications-after-filter/agc-extra-controls.js",
      "scope": () => [_modifier.on, _discourseI18n.i18n, _routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcUserNotificationsExtraControls;
});