define("discourse/plugins/discourse-global-communities/discourse/components/agc-toast", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _dButton, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcToast extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{icon this.icon}}
        <div class="agc-toast__message">
          <p class="agc-toast__message__text">{{this.message}}
            {{#if this.showLink}}
              <a
                href={{this.linkHref}}
                class="agc-toast__message__link"
                {{on "click" this.onClickLink}}
              >
                {{this.linkText}}
              </a>
            {{/if}}
    
            {{this.afterLinkText}}
    
            {{#if this.snoozeKey}}
              <a
                href
                class="agc-toast__message__snooze"
                {{on "click" this.onClickSnooze}}
              >
                {{i18n "agc_toasts.snooze"}}
              </a>
            {{/if}}
          </p>
        </div>
        <div class="agc-toast__close-container">
          <DButton class="btn-flat" @icon="xmark" @action={{@close}} />
        </div>
      
    */
    {
      "id": "pkFA+VeW",
      "block": "[[[1,\"\\n    \"],[1,[28,[32,0],[[30,0,[\"icon\"]]],null]],[1,\"\\n    \"],[10,0],[14,0,\"agc-toast__message\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"agc-toast__message__text\"],[12],[1,[30,0,[\"message\"]]],[1,\"\\n\"],[41,[30,0,[\"showLink\"]],[[[1,\"          \"],[11,3],[16,6,[30,0,[\"linkHref\"]]],[24,0,\"agc-toast__message__link\"],[4,[32,1],[\"click\",[30,0,[\"onClickLink\"]]],null],[12],[1,\"\\n            \"],[1,[30,0,[\"linkText\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[1,[30,0,[\"afterLinkText\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"snoozeKey\"]],[[[1,\"          \"],[11,3],[24,6,\"\"],[24,0,\"agc-toast__message__snooze\"],[4,[32,1],[\"click\",[30,0,[\"onClickSnooze\"]]],null],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"agc_toasts.snooze\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"agc-toast__close-container\"],[12],[1,\"\\n      \"],[8,[32,3],[[24,0,\"btn-flat\"]],[[\"@icon\",\"@action\"],[\"xmark\",[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@close\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-toast.js",
      "scope": () => [_dIcon.default, _modifier.on, _discourseI18n.i18n, _dButton.default],
      "isStrictMode": true
    }), this))();
    get icon() {}
    get message() {}
    get linkText() {}
    get linkHref() {}
    get showLink() {}
    onClickSnooze(e) {
      e.preventDefault();
      localStorage.setItem(this.snoozeKey, new Date());
      this.args.close();
    }
    static #_2 = (() => dt7948.n(this.prototype, "onClickSnooze", [_object.action]))();
  }
  _exports.default = AgcToast;
});