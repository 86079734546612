define("discourse/plugins/discourse-global-communities/discourse/helpers/circles-logo", ["exports", "@glimmer/tracking", "@ember/object", "@ember/template", "discourse/lib/avatar-utils", "discourse/lib/get-owner", "discourse/lib/get-url", "discourse/lib/helpers"], function (_exports, _tracking, _object, _template, _avatarUtils, _getOwner, _getUrl, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GcCircleLetterLogoHelper = void 0;
  _exports.default = topicCircleLogo;
  (0, _helpers.registerRawHelper)("topic-circle-logo", topicCircleLogo);
  function topicCircleLogo(topic) {
    const circle = topic.circle;
    if (!circle) {
      return;
    }
    const {
      uploaded_logo: logo,
      uploaded_logo_dark: logoDark
    } = circle;
    if (logo && logoDark) {
      return (0, _template.htmlSafe)(`<div class="circles__logo">
         <picture>
           <source srcset="${logoDark.url}"
                   width="${logoDark.width}"
                   height="${logoDark.height}"
                   media="(prefers-color-scheme: dark)" />
           ${cdnImg(defaultCircleLogo(circle))}
         </picture>
       </div>`);
    }
    if (logo) {
      return (0, _template.htmlSafe)(`<div class="circles__logo">${cdnImg(logo)}</div>`);
    }
    const letterLogo = new GcCircleLetterLogoHelper(circle);
    return (0, _template.htmlSafe)(`<div class="circles__logo" style="${letterLogo.avatarStyle}">
       ${letterLogo.imgElement}
     </div>`);
  }
  class GcCircleLetterLogoHelper {
    static #_ = (() => dt7948.g(this.prototype, "slug", [_tracking.tracked]))();
    #slug = (() => (dt7948.i(this, "slug"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked]))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "circleColor", [_tracking.tracked]))();
    #circleColor = (() => (dt7948.i(this, "circleColor"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "imageSize", [_tracking.tracked]))();
    #imageSize = (() => (dt7948.i(this, "imageSize"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "className", [_tracking.tracked]))();
    #className = (() => (dt7948.i(this, "className"), void 0))();
    constructor(circle) {
      let {
        imageSize,
        className
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.name = circle.name;
      this.slug = circle.slug;
      this.circleColor = circle.color;
      this.imageSize = imageSize;
      this.className = className;
    }
    get letter() {
      const letter = (this.slug || this.name).substring(0, 1).toLowerCase();
      if (/^[a-z0-9]/.test(letter)) {
        return letter;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "letter", [(0, _object.computed)("slug", "name")]))();
    get color() {
      const color = this.circleColor;
      if (/^[0-9a-fA-F]{6}/.test(color)) {
        return color;
      }
      return "ffffff";
    }
    static #_7 = (() => dt7948.n(this.prototype, "color", [(0, _object.computed)("circleColor")]))();
    get avatarStyle() {
      return (0, _template.htmlSafe)("background-color: #" + this.color);
    }
    static #_8 = (() => dt7948.n(this.prototype, "avatarStyle", [(0, _object.computed)("color")]))();
    get avatarTemplate() {
      const siteSetting = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site-settings");
      return siteSetting.external_system_avatars_url.replaceAll("{first_letter}", encodeURIComponent(this.letter)).replaceAll("{color}", encodeURIComponent(this.color));
    }
    static #_9 = (() => dt7948.n(this.prototype, "avatarTemplate", [(0, _object.computed)("letter", "color")]))();
    get imgElement() {
      return (0, _avatarUtils.avatarImg)({
        size: this.imageSize || "extra_large",
        extraClasses: "letter",
        avatarTemplate: this.avatarTemplate
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "imgElement", [(0, _object.computed)("imageSize", "className", "letter")]))();
  }
  _exports.GcCircleLetterLogoHelper = GcCircleLetterLogoHelper;
  function cdnImg(logo) {
    const cdnSrc = (0, _getUrl.getURLWithCDN)(logo.url);
    const {
      width,
      height
    } = logo;
    const style = width && height ? `--aspect-ratio: ${width / height};` : "";
    return `<img src="${cdnSrc}" width="${width}" height="${height}" style="${style}" alt="">`;
  }
  function defaultCircleLogo(circle) {
    let session = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:session");

    // from category-logo.js

    // use dark logo by default in edge case
    // when scheme is dark and dark logo is present
    if (session.defaultColorSchemeIsDark && circle.uploaded_logo_dark) {
      return circle.uploaded_logo_dark;
    }
    return circle.uploaded_logo;
  }
});