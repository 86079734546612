define("discourse/plugins/discourse-global-communities/discourse/services/agc-program-pillars", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/decorators", "discourse-i18n"], function (_exports, _tracking, _service, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcProgramPillars extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "loadMorePressed", [_tracking.tracked], function () {
      return false;
    }))();
    #loadMorePressed = (() => (dt7948.i(this, "loadMorePressed"), void 0))(); // tracks when the "Load More" button was pressed on the program pillars page
    get hasAny() {
      return this.site.program_pillars?.length > 0;
    }
    get firstAvailable() {
      return this.site.program_pillars?.[0];
    }
    titleFor(programPillar) {
      const locale = _discourseI18n.default.currentLocale();
      const key = `program-pillars-title-${programPillar.region}`;
      if (_discourseI18n.default.translations[locale].js[key]) {
        return (0, _discourseI18n.i18n)(key);
      }
      return (0, _discourseI18n.i18n)("program_pillars.title");
    }
    subtitleFor(programPillar) {
      const locale = _discourseI18n.default.currentLocale();
      const key = `program-pillars-subtitle-${programPillar.region}`;
      if (_discourseI18n.default.translations[locale].js[key]) {
        return (0, _discourseI18n.i18n)(key);
      }
      return (0, _discourseI18n.i18n)("program_pillars.landing_page_description");
    }
    widgetFooterLinkTextFor(programPillar) {
      const locale = _discourseI18n.default.currentLocale();
      const key = `program-pillars-link-text-${programPillar.region}`;
      if (_discourseI18n.default.translations[locale].js[key]) {
        return (0, _discourseI18n.i18n)(key);
      }
      return (0, _discourseI18n.i18n)("program_pillars.home_widget_link_label");
    }
    findByRegion(region) {
      return this.site.program_pillars?.find(pp => pp.region === region);
    }
    startTrackingState() {
      this.messageBus.subscribe(`/program-pillars`, this.updateUnreadCount);
      this.calculateUnreadCount();
    }
    calculateUnreadCount() {
      this.hasUnread = this.site.program_pillars?.some(pp => pp.unread_count > 0);
      this.#updateBodyClass();
    }
    updateUnreadCount(payload) {
      const pillar = this.findByRegion(payload.region);
      if (!pillar) {
        return;
      }
      if (payload.type === "update_unread_count") {
        pillar.unread_count = payload.unread_count;
        this.calculateUnreadCount();
      } else if (payload.type === "new_topic_created") {
        pillar.unread_count = (pillar.unread_count || 0) + 1;
        this.calculateUnreadCount();
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateUnreadCount", [_decorators.bind]))();
    #updateBodyClass() {
      document.body.classList.toggle("has-unread-program-pillars", this.hasUnread);
    }
  }
  _exports.default = AgcProgramPillars;
});