define("discourse/plugins/discourse-global-communities/discourse/lib/agc-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contentTypeToSearchFilter = contentTypeToSearchFilter;
  function contentTypeToSearchFilter(gcContentType) {
    switch (gcContentType.replace(/\s/g, "").toLowerCase()) {
      case "poll":
        return "in:polls";
      case "question":
        return "in:questions";
      case "tv":
        return "in:tv";
      default:
        return "in:staged_content";
    }
  }
});