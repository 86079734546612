define("discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/discourse-notifications-disabled-toast", ["exports", "@ember/object", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-toast"], function (_exports, _object, _service, _discourseI18n, _agcToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SNOOZE_KEY = void 0;
  const SNOOZE_KEY = _exports.SNOOZE_KEY = "discourse_notifications_disabled_toast";
  class DiscourseNotificationsDisabledToast extends _agcToast.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get icon() {
      return "info";
    }
    get message() {
      return (0, _discourseI18n.i18n)("discourse_notifications_disabled_toast.message");
    }
    onClickLink(e) {
      e.preventDefault();
      this.router.transitionTo("preferences.notifications", this.currentUser.username);
      this.args.close();
      return false;
    }
    static #_3 = (() => dt7948.n(this.prototype, "onClickLink", [_object.action]))();
    get showLink() {
      return true;
    }
    get linkHref() {
      return "/my/preferences/notifications";
    }
    get linkText() {
      return (0, _discourseI18n.i18n)("discourse_notifications_disabled_toast.link");
    }
    get afterLinkText() {
      return (0, _discourseI18n.i18n)("discourse_notifications_disabled_toast.after_link");
    }
    get snoozeKey() {
      return SNOOZE_KEY;
    }
  }
  _exports.default = DiscourseNotificationsDisabledToast;
});