define("discourse/plugins/discourse-global-communities/discourse/components/post-tags", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse/lib/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="discourse-tags" id="post-tags-container">
    {{#each this.postTags as |tag|}}
      {{discourse-tag tag noHref=true}}
    {{/each}}
  </div>
  */
  {
    "id": "A2WLxCr8",
    "block": "[[[10,0],[14,0,\"discourse-tags\"],[14,1,\"post-tags-container\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"postTags\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],[[30,1]],[[\"noHref\"],[true]]]],[1,\"\\n\"]],[1]],null],[13]],[\"tag\"],false,[\"div\",\"each\",\"-track-array\",\"discourse-tag\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/post-tags.hbs",
    "isStrictMode": false
  });
  class PostTags extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "postTags", [_tracking.tracked], function () {
      return [];
    }))();
    #postTags = (() => (dt7948.i(this, "postTags"), void 0))();
    postId;
    constructor() {
      super(...arguments);
      this.postTags = this.args?.postTags;
      this.postId = this.args?.postId;
      this.appEvents.on(`display-post-tags-${this.postId}`, this.handleDisplayPostTags);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(`display-post-tags-${this.postId}`, this.handleDisplayPostTags);
    }
    handleDisplayPostTags(event) {
      if (event?.success_post) {
        this.postTags = event.success_post.post_tags;
      } else {
        this.handlePostTagsError(event);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleDisplayPostTags", [_decorators.bind]))();
    handlePostTagsError(event) {
      const errorPost = event?.error_post;
      if (!errorPost) {
        this.dialog.alert((0, _discourseI18n.i18n)("generic_error"));
        return;
      }
      const errorCode = errorPost?.error_code;
      let errorMessage;
      switch (errorCode) {
        case 207:
          const tagNames = errorPost.error_tags.join(",");
          errorMessage = (0, _discourseI18n.i18n)("posts.error.multi_status", {
            error_tag_names: tagNames
          });
          break;
        case 422:
          errorMessage = (0, _discourseI18n.i18n)("posts.error.unprocessabe_content");
          break;
        case 500:
          errorMessage = (0, _discourseI18n.i18n)("posts.error.internal_service_error");
          break;
        default:
          errorMessage = (0, _discourseI18n.i18n)("generic_error");
      }
      this.dialog.alert(errorMessage);
    }
    static #_5 = (() => dt7948.n(this.prototype, "handlePostTagsError", [_decorators.bind]))();
  }
  _exports.default = PostTags;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostTags);
});