define("discourse/plugins/discourse-global-communities/discourse/feature-flags/merged-topics", ["exports", "@ember/runloop", "discourse/widgets/render-glimmer", "@ember/template-factory"], function (_exports, _runloop, _renderGlimmer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function filteringExpertResponses(filter) {
    return filter === "amazon_responses" || filter === "guided_responses";
  }
  class MergedTopicsFeatureFlag {
    static enabledKey = "agc_merged_topics_enabled";
    static className = "agc-merged-topics";
    static afterEnabled(api) {
      // We add a checkbox in the move posts modal, where moderators can select "copy posts" rather
      // than simply moving posts. Here we modify the request payload based on the value chosen in
      // the modal :)
      const agcMovePostsService = api.container.lookup("service:agc-move-posts");
      const siteSettings = api.container.lookup("service:site-settings");
      ["move-to-topic-merge-options", "move-to-topic-move-options"].forEach(transformerName => {
        api.registerValueTransformer(transformerName, transformer => {
          if (siteSettings.agc_merged_topics_enabled && agcMovePostsService.copyPostsOnMove) {
            transformer.value.freeze_original = true;
          }
          return transformer.value;
        });
      });

      // Include `agc_merged_topic` key/value pair in the post object passed to post stream renderer
      api.includePostAttributes("agc_merged_topic");

      // Add classNames to small-action posts based on type
      api.addPostSmallActionClassesCallback(post => {
        return [post.actionCode];
      });

      // **************************************************
      // Merged post header -- sits above the first post in a collection of posts
      // moved from another topic
      // **************************************************
      api.decorateWidget("post-article:before", helper => {
        // Return early if we are filtering category expert responses.
        if (filteringExpertResponses(helper.attrs.topic.filter)) {
          return;
        }

        // Return early if we are in the first post, or if the post doesn't have a merged topic
        if (helper.attrs.post_number === 1 || !helper.attrs.agc_merged_topic) {
          return;
        }

        // Return early if the previous post is associated with THE SAME merged topic. No header needed
        const previousPostMergedTopicId = helper.attrs?.prevPost?.agc_merged_topic?.old_topic_id;
        if (previousPostMergedTopicId && previousPostMergedTopicId === helper.attrs.agc_merged_topic.old_topic_id) {
          return;
        }

        // We are starting a grouping of merged posts, add header
        return [new _renderGlimmer.default(helper.widget, "div.merged-post-header", (0, _templateFactory.createTemplateFactory)(
        /*
          <AgcMergedPostHeader/>
        */
        {
          "id": "dwgXypoq",
          "block": "[[[8,[39,0],null,null,null]],[],false,[\"agc-merged-post-header\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/merged-topics.js",
          "isStrictMode": false
        }))];
      });

      // **************************************************
      // Merged post footer -- sits under post menu for the first post in a collection of posts
      // moved from another topic
      // **************************************************
      api.decorateWidget("post-body:after", helper => {
        // Return early if we are filtering category expert responses.
        if (filteringExpertResponses(helper.attrs.topic.filter)) {
          return;
        }

        // Return early if we are in the first post, or if the post doesn't have a merged topic
        if (helper.attrs.post_number === 1 || !helper.attrs.agc_merged_topic) {
          return;
        }

        // Return early if the previous post is associated with THE SAME merged topic. No header needed
        const previousPostMergedTopicId = helper.attrs?.prevPost?.agc_merged_topic?.old_topic_id;
        if (previousPostMergedTopicId && previousPostMergedTopicId === helper.attrs.agc_merged_topic.old_topic_id) {
          return;
        }
        const nextPostMergedTopicId = helper.attrs?.nextPost?.agc_merged_topic?.old_topic_id;
        const multipleMoved = nextPostMergedTopicId && nextPostMergedTopicId === helper.attrs.agc_merged_topic.old_topic_id;

        // We are starting a grouping of merged posts, add footer
        return [new _renderGlimmer.default(helper.widget, "div.merged-post-footer", (0, _templateFactory.createTemplateFactory)(
        /*
          <AgcMergedPostFooter @date={{@data.date}} @url={{@data.url}} @title={{@data.title}} @fullMove={{@data.fullMove}} @multiple={{@data.multiple}} @who={{@data.who}}/>
        */
        {
          "id": "1ELirJDe",
          "block": "[[[8,[39,0],null,[[\"@date\",\"@url\",\"@title\",\"@fullMove\",\"@multiple\",\"@who\"],[[30,1,[\"date\"]],[30,1,[\"url\"]],[30,1,[\"title\"]],[30,1,[\"fullMove\"]],[30,1,[\"multiple\"]],[30,1,[\"who\"]]]],null]],[\"@data\"],false,[\"agc-merged-post-footer\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/merged-topics.js",
          "isStrictMode": false
        }), {
          date: helper.attrs.agc_merged_topic.date,
          url: helper.attrs.agc_merged_topic.old_topic_url,
          title: helper.attrs.agc_merged_topic.old_topic_title,
          fullMove: helper.attrs.agc_merged_topic.full_move,
          multiple: multipleMoved,
          who: helper.attrs.agc_merged_topic.who
        })];
      });

      // **************************************************
      // Merged post separator -- sits after a collection of posts that were merged from another topic
      // **************************************************
      api.decorateWidget("post-article:after", helper => {
        // Return early if we are filtering category expert responses.
        if (filteringExpertResponses(helper.attrs.topic.filter)) {
          return;
        }

        // Return early if we are in the first post, or if the post doesn't have a merged topic
        if (helper.attrs.post_number === 1 || !helper.attrs.agc_merged_topic) {
          return;
        }

        // Return early if there is no next post
        if (!helper.attrs.nextPost) {
          return;
        }

        // Return early if the next post also has the same merged topic
        const nextPostMergedTopicId = helper.attrs?.nextPost?.agc_merged_topic?.old_topic_id;
        if (nextPostMergedTopicId && nextPostMergedTopicId === helper.attrs.agc_merged_topic.old_topic_id) {
          return;
        }

        // Return early if the previous post DOESN'T have a merged topic, OR if it does have one, but it's
        // for a different topic -- TLDR; don't show for only one merged post. Thats what all those conditions mean.
        if (!helper.attrs?.prevPost?.agc_merged_topic?.old_topic_id || helper.attrs?.prevPost?.agc_merged_topic?.old_topic_id !== helper.attrs.agc_merged_topic.old_topic_id) {
          return;
        }

        // We are the last in a collection of one or more moved posts, add separator!
        return [new _renderGlimmer.default(helper.widget, "div.merged-post-separator", (0, _templateFactory.createTemplateFactory)(
        /*
          <AgcMergedPostSeparator/>
        */
        {
          "id": "zjLNJZOH",
          "block": "[[[8,[39,0],null,null,null]],[],false,[\"agc-merged-post-separator\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/merged-topics.js",
          "isStrictMode": false
        }))];
      });

      // **************************************************
      // OP grouped post links section
      // **************************************************
      api.decorateWidget("post-links:before", helper => {
        if (helper.attrs.post_number !== 1) {
          return;
        }
        return [new _renderGlimmer.default(helper.widget, "div", (0, _templateFactory.createTemplateFactory)(
        /*
          <AgcMergedTopicPostLinks @hasOtherLinks={{@data.hasOtherLinks}} @mergedTopics={{@data.mergedTopics}}/>
        */
        {
          "id": "tL93Ubvm",
          "block": "[[[8,[39,0],null,[[\"@hasOtherLinks\",\"@mergedTopics\"],[[30,1,[\"hasOtherLinks\"]],[30,1,[\"mergedTopics\"]]]],null]],[\"@data\"],false,[\"agc-merged-topic-post-links\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/merged-topics.js",
          "isStrictMode": false
        }), {
          hasOtherLinks: helper.attrs.links?.length,
          mergedTopics: helper.attrs?.topic?.agc_merged_topics
        })];
      });

      // **************************************************
      // * Add `has-moved-post-header` to the post article, when the header exists
      // within it.
      // * Add `has-merged-post-separator` to the topic-post (article's parents) when
      // separator is present.
      //
      // It's an ugly solution but I don't want to mess with widget
      // internals at the time of writing this, with modernization in effect.
      // **************************************************
      api.decorateWidget("post:after", helper => {
        if (!helper.attrs.agc_merged_topic) {
          return;
        }
        (0, _runloop.next)(() => {
          const article = document.querySelector(`article[data-post-id="${helper.attrs.id}"]`);
          if (!article) {
            return;
          }
          const hasHeader = article.querySelector(".merged-post-header");
          if (hasHeader) {
            return article.classList.add("has-merged-post-header");
          }
          const hasSeparator = article.querySelector(".merged-post-separator");
          if (hasSeparator && article.parentNode.classList.contains("topic-post")) {
            return article.parentNode.classList.add("has-merged-post-separator");
          }
        });
      });
    }
  }
  _exports.default = MergedTopicsFeatureFlag;
});