define("discourse/plugins/discourse-global-communities/discourse/components/program-pillars-side-widget-post-badge", ["exports", "@glimmer/component", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProgramPillarsSideWidgetPostBadge extends _component.default {
    constructor() {
      super(...arguments);
      this.hasNewTopic = this.args.topics.some(t => t.unseen);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.hasNewTopic}}
          <span class="badge badge-notification new-posts new">
            {{i18n "agc.topic-list.new_title"}}
          </span>
        {{/if}}
      
    */
    {
      "id": "Gx4ExDJx",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"hasNewTopic\"]],[[[1,\"      \"],[10,1],[14,0,\"badge badge-notification new-posts new\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"agc.topic-list.new_title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/program-pillars-side-widget-post-badge.js",
      "scope": () => [_discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ProgramPillarsSideWidgetPostBadge;
});