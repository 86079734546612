define("discourse/plugins/discourse-global-communities/discourse/components/agc-collapsible-widget", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "discourse/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcCollapsibleWidget extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "isCollapsed", [_tracking.tracked], function () {
      return this.args.isCollapsible;
    }))();
    #isCollapsed = (() => (dt7948.i(this, "isCollapsed"), void 0))();
    toggleCollapsed() {
      if (this.args.isCollapsible) {
        this.isCollapsed = !this.isCollapsed;
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleCollapsed", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="agc-collapsible-widget homepage-hero-cell" ...attributes>
          <div
            class="agc-collapsible-widget-header
              {{if @isCollapsible 'collapsible'}}
              {{if this.isCollapsed 'collapsed'}}"
            role="button"
            {{on "click" this.toggleCollapsed}}
          >
            {{#if @title}}
              <div class="agc-collapsible-widget-title">
                <div class="cell-title">
                  <h3 class="title">
                    {{@title}}
                  </h3>
                  {{yield to="afterTitle"}}
                  {{#if @subtitle}}
                    <h4 class="subtitle">{{@subtitle}}</h4>
                  {{/if}}
                </div>
              </div>
            {{/if}}
            {{#if @isCollapsible}}
              {{dIcon (if this.isCollapsed "caret-down" "caret-up")}}
            {{/if}}
          </div>
          {{#unless this.isCollapsed}}
            <div class="agc-collapsible-widget-body">
              <div class="agc-collapsible-widget-content">
                {{yield to="content"}}
              </div>
              <div class="agc-collapsible-widget-footer">
                {{yield to="footer"}}
              </div>
            </div>
          {{/unless}}
        </div>
      
    */
    {
      "id": "TvEZmjfD",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"agc-collapsible-widget homepage-hero-cell\"],[17,1],[12],[1,\"\\n      \"],[11,0],[16,0,[29,[\"agc-collapsible-widget-header\\n          \",[52,[30,2],\"collapsible\"],\"\\n          \",[52,[30,0,[\"isCollapsed\"]],\"collapsed\"]]]],[24,\"role\",\"button\"],[4,[32,0],[\"click\",[30,0,[\"toggleCollapsed\"]]],null],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"          \"],[10,0],[14,0,\"agc-collapsible-widget-title\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"cell-title\"],[12],[1,\"\\n              \"],[10,\"h3\"],[14,0,\"title\"],[12],[1,\"\\n                \"],[1,[30,3]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[18,5,null],[1,\"\\n\"],[41,[30,4],[[[1,\"                \"],[10,\"h4\"],[14,0,\"subtitle\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[41,[30,2],[[[1,\"          \"],[1,[28,[32,1],[[52,[30,0,[\"isCollapsed\"]],\"caret-down\",\"caret-up\"]],null]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"isCollapsed\"]]],[[[1,\"        \"],[10,0],[14,0,\"agc-collapsible-widget-body\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"agc-collapsible-widget-content\"],[12],[1,\"\\n            \"],[18,6,null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"agc-collapsible-widget-footer\"],[12],[1,\"\\n            \"],[18,7,null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@isCollapsible\",\"@title\",\"@subtitle\",\"&afterTitle\",\"&content\",\"&footer\"],false,[\"if\",\"yield\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-collapsible-widget.js",
      "scope": () => [_modifier.on, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcCollapsibleWidget;
});