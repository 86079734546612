define("discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/ai-search-enabled-toast", ["exports", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-toast"], function (_exports, _discourseI18n, _agcToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiSearchEnabledToast extends _agcToast.default {
    get icon() {
      return "info";
    }
    get message() {
      return (0, _discourseI18n.i18n)("discourse_ai.enabled_toast");
    }
  }
  _exports.default = AiSearchEnabledToast;
});