define("discourse/plugins/discourse-global-communities/discourse/components/circles/extra-activity-components", ["exports", "@glimmer/component", "@ember/modifier", "@ember/service", "discourse/helpers/d-icon", "discourse/helpers/route-action", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/circles/featured-members", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _service, _dIcon, _routeAction, _discourseI18n, _featuredMembers, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GcActivityComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentCircle", [_service.service]))();
    #currentCircle = (() => (dt7948.i(this, "currentCircle"), void 0))();
    get displayComposerPromptBtn() {
      return this.currentCircle?.userCanCreateTopic;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.displayComposerPromptBtn}}
          <button
            type="button"
            class="composer-prompt"
            {{on "click" (routeAction "clickCreateTopicButton")}}
          >
            {{dIcon "pencil"}}
            <span class="composer-prompt-text">{{i18n "agc.composer.prompt"}}</span>
          </button>
        {{/if}}
        <CirclesFeaturedMembers
          @users={{@outletArgs.circleModel.featured_members}}
          @totalUsers={{@outletArgs.circleModel.member_group.user_count}}
        />
      
    */
    {
      "id": "o4GhGKhC",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"displayComposerPromptBtn\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"composer-prompt\"],[24,4,\"button\"],[4,[32,0],[\"click\",[28,[32,1],[\"clickCreateTopicButton\"],null]],null],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"pencil\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"composer-prompt-text\"],[12],[1,[28,[32,3],[\"agc.composer.prompt\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[32,4],null,[[\"@users\",\"@totalUsers\"],[[30,1,[\"circleModel\",\"featured_members\"]],[30,1,[\"circleModel\",\"member_group\",\"user_count\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/extra-activity-components.js",
      "scope": () => [_modifier.on, _routeAction.default, _dIcon.default, _discourseI18n.i18n, _featuredMembers.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GcActivityComponent;
});