define("discourse/plugins/discourse-global-communities/discourse/widgets/amazon-comments-info-widget", ["virtual-dom", "discourse/lib/icon-library", "discourse/lib/url", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse-i18n"], function (_virtualDom, _iconLibrary, _url, _rawHtml, _widget, _discourseI18n) {
  "use strict";

  (0, _widget.createWidget)("amazon-comments-info", {
    tagName: "",
    buildKey: attrs => `amazon-comments-info-${attrs.topic.id}`,
    amazonCommentsCount(topic) {
      return topic.expert_post_group_count && topic.expert_post_group_count["Amazonians"];
    },
    description(topic) {
      if (this.amazonCommentsCount(topic) === 1) {
        return (0, _discourseI18n.i18n)("agc.responses_filter.amazon_comments_info_singular");
      } else if (this.amazonCommentsCount(topic) > 1) {
        return (0, _discourseI18n.i18n)("agc.responses_filter.amazon_comments_info_plural", {
          count: this.amazonCommentsCount(topic).toString()
        });
      }
    },
    seeAllLabel(topic) {
      if (this.amazonCommentsCount(topic) === 1) {
        return (0, _discourseI18n.i18n)("agc.responses_filter.amazon_comments_see_it");
      } else if (this.amazonCommentsCount(topic) > 1) {
        return (0, _discourseI18n.i18n)("agc.responses_filter.amazon_comments_see_all");
      }
    },
    seeAllAction() {
      const postStream = this.attrs.topic.postStream;
      postStream.cancelFilter();
      postStream.refresh({
        filter: "amazon_responses"
      }).then(() => {
        this.appEvents.trigger("enhanced_user_analytics:event", "page_view", {}, {
          clickRef: "CTA_see_all_comments"
        });
        _url.default.jumpToPost("1", {
          anchor: "comments-container"
        });
      });
    },
    html(_ref) {
      let {
        topic
      } = _ref;
      if (topic.filter !== "amazon_responses" && this.amazonCommentsCount(topic) > 0) {
        return (0, _virtualDom.h)(".amazon-comments-info", [(0, _iconLibrary.iconNode)("circle-check"), new _rawHtml.default({
          html: `<span>${this.description(topic)}</span>`
        }), this.attach("link", {
          rawLabel: this.seeAllLabel(topic),
          action: "seeAllAction"
        })]);
      }
    }
  });
});