define("discourse/plugins/discourse-global-communities/discourse/helpers/formatted", ["exports", "@ember/template", "discourse/lib/helpers", "discourse-i18n"], function (_exports, _template, _helpers, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatted;
  _exports.formattedSchedule = formattedSchedule;
  (0, _helpers.registerRawHelper)("formatted", formatted);
  function formatted(dt) {
    return (0, _template.htmlSafe)(moment(dt).format((0, _discourseI18n.i18n)("dates.full_with_year_no_time")));
  }
  function formattedSchedule(start, end) {
    const startMoment = moment(start);
    const endMoment = moment(end);
    return `${startMoment.format("LT")} - ${endMoment.format("LT")}, ${startMoment.format((0, _discourseI18n.i18n)("dates.full_with_year_no_time"))}`;
  }
});