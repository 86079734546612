define("discourse/plugins/discourse-global-communities/discourse/routes/program-pillars", ["exports", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/models/topic-list", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _service, _rsvp, _ajax, _topicList, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProgramPillarsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "agcProgramPillars", [_service.service]))();
    #agcProgramPillars = (() => (dt7948.i(this, "agcProgramPillars"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    queryParams = {
      t: {
        refreshModel: true
      }
    };
    async model(data) {
      const programPillar = this.agcProgramPillars.findByRegion(data.region);
      if (!programPillar) {
        return this.router.replaceWith("/404");
      }
      try {
        let [list, countsAndWidgetTopicLists] = await _rsvp.Promise.all([this.fetchTopicList(programPillar, data.t), this.fetchCountsAndWidgetTopicLists(programPillar, data.t)]);
        list = this.store.createRecord("topicList", _topicList.default.munge(list, this.store));
        countsAndWidgetTopicLists.child_tag_topic_counts.forEach(childTagCount => {
          const childTag = programPillar.child_tags.find(ct => ct.id === childTagCount.id);
          if (childTag) {
            childTag.count = childTagCount.count;
          }
        });
        programPillar.total_count = countsAndWidgetTopicLists.total_topic_count;
        programPillar.sideWidgets = this.#decorateSideWidgets(countsAndWidgetTopicLists.side_widgets, programPillar.child_tags || [], data.t);
        list.set("programPillar", programPillar);
        return list;
      } catch (error) {
        // Return an empty model in the case that an ajax request errored out.
        // We have an empty state defined in the template.
        // eslint-disable-next-line no-console
        console.warn(error);
        return;
      }
    }
    async fetchTopicList(config, childTag) {
      return await (0, _ajax.ajax)(this.#endpointWithChildTagParam(`/program-pillars/${config.region}/list.json`, childTag));
    }
    async fetchCountsAndWidgetTopicLists(config, childTag) {
      return await (0, _ajax.ajax)(this.#endpointWithChildTagParam(`/program-pillars/${config.region}.json`, childTag));
    }
    #endpointWithChildTagParam(endpoint, childTag) {
      if (childTag) {
        endpoint = `${endpoint}?child_tag_name=${childTag}`;
      }
      return endpoint;
    }
    #decorateSideWidgets(sideWidgets, childTags, selectedChildTagName) {
      sideWidgets.forEach(sideWidget => {
        let allTopics = sideWidget.topics.map(t => this.store.createRecord("topic", t));
        const [topics, additionalTopics] = this.#splitSideWidgetTopics(allTopics);
        sideWidget.allTopics = allTopics;
        sideWidget.topics = topics;
        sideWidget.additionalTopics = additionalTopics;

        // Get title translation
        sideWidget.translatedTitle = this.#widgetTitle(sideWidget, childTags, selectedChildTagName);
      });

      // Filter out widgets with no translated title. They would look horrible, just hide them.
      return sideWidgets.filter(widget => widget.translatedTitle);
    }
    #splitSideWidgetTopics(topics) {
      if (this.site.mobileView) {
        return [topics, null];
      }
      return [topics.slice(0, 5), topics.slice(5)];
    }
    #widgetTitle(sideWidget, childTags, selectedChildTagName) {
      const count = sideWidget.allTopics.length;
      if (selectedChildTagName && selectedChildTagName !== "all") {
        if (!sideWidget.filtered_translation_key) {
          return;
        }
        let tag = this.#childTagForTagName(childTags, selectedChildTagName)?.translation_key ? (0, _discourseI18n.i18n)(this.#childTagForTagName(childTags, selectedChildTagName).translation_key) : selectedChildTagName;
        return (0, _discourseI18n.i18n)(sideWidget.filtered_translation_key, {
          tag,
          count
        });
      } else {
        if (!sideWidget.unfiltered_translation_key) {
          return;
        }
        return (0, _discourseI18n.i18n)(sideWidget.unfiltered_translation_key, {
          count
        });
      }
    }
    #childTagForTagName(childTags, selectedChildTagName) {
      return childTags.find(ct => ct.tag_name === selectedChildTagName);
    }
  }
  _exports.default = ProgramPillarsRoute;
});