define("discourse/plugins/discourse-global-communities/discourse/components/gc-aux-login", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _object, _ajax, _ajaxError, _decorators, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class="gc-aux-login-text">
    <DiscourseLinkedText
      @action={{this.toggleForm}}
      @text="discourse_gc.login.aux"
    />
  </p>
  
  {{#if this.showingForm}}
    <div class="gc-aux-login">
      <Input
        name="username"
        placeholder={{i18n "user.username.title"}}
        disabled={{this.loggingIn}}
        @value={{this.username}}
      />
      <Input
        name="password"
        placeholder={{i18n "user.password.title"}}
        disabled={{this.loggingIn}}
        @type="password"
        @value={{this.password}}
      />
      <DButton
        @action={{this.login}}
        @label="login.title"
        @disabled={{this.buttonDisabled}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "rpGIIeVy",
    "block": "[[[10,2],[14,0,\"gc-aux-login-text\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@action\",\"@text\"],[[30,0,[\"toggleForm\"]],\"discourse_gc.login.aux\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showingForm\"]],[[[1,\"  \"],[10,0],[14,0,\"gc-aux-login\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,3,\"username\"],[16,\"placeholder\",[28,[37,5],[\"user.username.title\"],null]],[16,\"disabled\",[30,0,[\"loggingIn\"]]]],[[\"@value\"],[[30,0,[\"username\"]]]],null],[1,\"\\n    \"],[8,[39,4],[[24,3,\"password\"],[16,\"placeholder\",[28,[37,5],[\"user.password.title\"],null]],[16,\"disabled\",[30,0,[\"loggingIn\"]]]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"password\"]]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"login\"]],\"login.title\",[30,0,[\"buttonDisabled\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"p\",\"discourse-linked-text\",\"if\",\"div\",\"input\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/gc-aux-login.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNames: "gc-aux-login-wrapper",
    showingForm: false,
    loggingIn: false,
    async login() {
      this.set("loggingIn", true);
      try {
        await (0, _ajax.ajax)("/aux-login", {
          type: "POST",
          data: {
            username: this.username,
            password: this.password
          }
        });
        _url.default.redirectTo("/");
      } catch (e) {
        this.set("loggingIn", false);
        (0, _ajaxError.popupAjaxError)(e);
      }
    },
    toggleForm() {
      this.set("showingForm", !this.showingForm);
    },
    keyDown(event) {
      if (event.key === "Enter" && !this.buttonDisabled) {
        this.send("login");
        return false;
      }
    },
    buttonDisabled() {
      return this.loggingIn || !(this.username && this.password);
    }
  }, [["method", "login", [_object.action]], ["method", "toggleForm", [_object.action]], ["method", "keyDown", [_decorators.bind]], ["method", "buttonDisabled", [(0, _decorators.default)("loggingIn", "username", "password")]]])));
});