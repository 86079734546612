define("discourse/plugins/discourse-global-communities/discourse/components/agc-hero", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse/lib/ajax", "discourse/lib/decorators", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _ajax, _decorators, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcHero extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "hero", [_tracking.tracked]))();
    #hero = (() => (dt7948.i(this, "hero"), void 0))();
    constructor() {
      super(...arguments);
      this.router.on("routeDidChange", this.fetchHero);
      this.fetchHero();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.off("routeDidChange", this.fetchHero);
    }
    async fetchHero() {
      this.hero = null;
      if (!this.onIncludedRoute) {
        return;
      }
      try {
        this.hero = await (0, _ajax.ajax)("/agc-heroes", {
          data: {
            route: this.router.currentURL.split("?")[0]
          }
        });
      } catch {}
    }
    static #_3 = (() => dt7948.n(this.prototype, "fetchHero", [_decorators.bind]))();
    get onIncludedRoute() {
      // Abstract later -- for now only used on program pillars page.
      return this.router.currentRouteName === "program-pillars";
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.hero.upload.url}}
          <div class="agc-hero-wrapper">
            <a href={{this.hero.href}} data-agc-analytics-ref="agc_hero">
              <img
                class="agc-hero-image"
                className="agc-hero-image"
                src={{this.hero.upload.url}}
              />
            </a>
          </div>
        {{/if}}
      
    */
    {
      "id": "2guGNCAV",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"hero\",\"upload\",\"url\"]],[[[1,\"      \"],[10,0],[14,0,\"agc-hero-wrapper\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,0,[\"hero\",\"href\"]]],[14,\"data-agc-analytics-ref\",\"agc_hero\"],[12],[1,\"\\n          \"],[10,\"img\"],[14,0,\"agc-hero-image\"],[14,\"className\",\"agc-hero-image\"],[15,\"src\",[30,0,[\"hero\",\"upload\",\"url\"]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-hero.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcHero;
});