define("discourse/plugins/discourse-global-communities/discourse/controllers/preferences-agc-home-feed", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse/lib/environment", "discourse-i18n"], function (_exports, _controller, _object, _service, _ajaxError, _decorators, _environment, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_SORTING_OPTIONS = _exports.AGC_DEFAULT_HOME_FEED_SORTING = void 0;
  const AGC_DEFAULT_HOME_FEED_SORTING = _exports.AGC_DEFAULT_HOME_FEED_SORTING = "agc_default_home_feed_sorting";
  const DEFAULT_SORTING_OPTIONS = _exports.DEFAULT_SORTING_OPTIONS = ["top", "latest"];
  const AGC_HOME_FEED_ATTRS = [AGC_DEFAULT_HOME_FEED_SORTING];
  class PreferencesAgcHomeFeedController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "agcAnalyticsEvents", [_service.service]))();
    #agcAnalyticsEvents = (() => (dt7948.i(this, "agcAnalyticsEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    defaultSortingOptions() {
      return DEFAULT_SORTING_OPTIONS.map(value => {
        return {
          name: (0, _discourseI18n.i18n)(`user.agc_preferences.home_feed.default_sorting_options.${value}`),
          value
        };
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "defaultSortingOptions", [_decorators.default]))();
    onChangeDefaultSorting(order) {
      this.model.set("user_option.agc_default_home_feed_sorting", order);
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChangeDefaultSorting", [_object.action]))();
    async save() {
      this.set("saved", false);
      try {
        await this.model.save(AGC_HOME_FEED_ATTRS);

        // analytics
        this.agcAnalyticsEvents.logUserDefaultHomeFeedSorting(this.model, this.model.user_option.agc_default_home_feed_sorting, "user-preferences");
        this.set("saved", true);
        if (!(0, _environment.isTesting)()) {
          location.reload();
        }
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = PreferencesAgcHomeFeedController;
});