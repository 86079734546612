define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs", ["exports", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/search-menu", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/user-menu-analytics"], function (_exports, _navs, _searchMenu, _userMenuAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcNavs {
    static enabledKey = "enable_agc_navs";
    static className = "agc-navs";
    static afterEnabled(api) {
      _navs.default.apply(api);
      _searchMenu.default.apply(api);
      _userMenuAnalytics.default.apply(api);
    }
  }
  _exports.default = AgcNavs;
});