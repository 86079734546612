define("discourse/plugins/discourse-global-communities/discourse/initializers/composer", ["exports", "@ember/service", "discourse/lib/decorators", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-i18n", "discourse/plugins/poll/discourse/components/modal/poll-ui-builder", "discourse/plugins/discourse-global-communities/discourse/config/streamline-posting"], function (_exports, _service, _decorators, _pluginApi, _composer, _discourseI18n, _pollUiBuilder, _streamlinePosting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "agc-base-component";
  var _default = _exports.default = {
    after: "agc-base-feature-flags",
    name: "agc-composer-buttons",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.registerBehaviorTransformer("composer-position:editor-touch-move", _ref => {
          let {
            next
          } = _ref;
          if (document.documentElement.classList.contains("keyboard-visible")) {
            // Return early if keyboard is open, allowing scrolling on the editor
            return;
          }

          // If keyboard is NOT open, we want to block the scroll event, to prevent scrolling the body
          next();
        });
        this._addMarkAsQuestion(api);
        const currentUser = api.getCurrentUser();
        if (currentUser && !currentUser.staff) {
          if (siteSettings.composer_toolbar_buttons !== "") {
            this._hideButtons(api);
          }
          this._forceToolbar(api);
          this._setStreamlinePostingToolbarButtons(api);
          this._setComposerFlag(api);
          this._updateComposerTitle(api);
        }
      });
    },
    _addMarkAsQuestion(api) {
      _composer.default.serializeOnCreate("gc_is_question", "gcIsQuestion");
      api.addComposerToolbarPopupMenuOption({
        icon: "question",
        label: "agc.mark_question",
        action: () => {
          const composer = api.container.lookup("service:composer");
          composer.set("model.gcIsQuestion", true);
        }
      });
    },
    _hideButtons(api) {
      api.modifyClass("component:d-editor", dt7948.p({
        pluginId: PLUGIN_ID,
        siteSettings: (0, _service.service)(),
        toolbar() {
          const toolbar = this._super(...arguments);
          const buttonIds = this.siteSettings.composer_toolbar_buttons.split("|");
          toolbar.groups.forEach(group => {
            group.buttons = group.buttons.filter(button => buttonIds.includes(button.id));

            // In core, bullets and lists are not included on mobile by default
            if (group.group === "extras" && this.site.mobileView) {
              if (buttonIds.includes("bullet")) {
                toolbar.addButton({
                  id: "bullet",
                  group: "extras",
                  icon: "list-ul",
                  shortcut: "Shift+8",
                  title: "composer.ulist_title",
                  preventFocus: true,
                  perform: e => e.applyList("* ", "list_item")
                });
              }
              if (buttonIds.includes("list")) {
                toolbar.addButton({
                  id: "list",
                  group: "extras",
                  icon: "list-ol",
                  shortcut: "Shift+7",
                  title: "composer.olist_title",
                  preventFocus: true,
                  perform: e => e.applyList(i => !i ? "1. " : `${parseInt(i, 10) + 1}. `, "list_item")
                });
              }
            }
          });
          toolbar.groups = toolbar.groups.filter(group => group.buttons.length > 0);
          toolbar.groups.forEach((group, index) => {
            group.lastGroup = index === toolbar.groups.length - 1;
          });
          return toolbar;
        }
      }, [["method", "toolbar", [(0, _decorators.default)()]]]));
    },
    _setStreamlinePostingToolbarButtons(api) {
      api.modifyClass("component:d-editor", dt7948.p({
        pluginId: PLUGIN_ID,
        toolbar() {
          const toolbar = this._super(...arguments);
          const {
            buttonOrder
          } = _streamlinePosting.streamlinePostingConfigs;
          if (this.site.mobileView) {
            toolbar.addButton({
              id: "list",
              group: "extras",
              icon: "list-ol",
              shortcut: "Shift+7",
              title: "composer.olist_title",
              preventFocus: true,
              perform: e => e.applyList(i => !i ? "1. " : `${parseInt(i, 10) + 1}. `, "list_item")
            });
            toolbar.addButton({
              id: "bullet",
              group: "extras",
              icon: "list-ul",
              shortcut: "Shift+8",
              title: "composer.ulist_title",
              preventFocus: true,
              perform: e => e.applyList("* ", "list_item")
            });
          }
          const customToolbar = [];
          toolbar.groups.forEach(group => {
            group.buttons.forEach(button => {
              if (button.id in buttonOrder) {
                const buttonIndex = buttonOrder[button.id] - 1;
                if (button.id === "upload") {
                  customToolbar[buttonIndex] = {
                    ...button,
                    id: "upload-attachment",
                    title: (0, _discourseI18n.i18n)("agc.composer.upload_file"),
                    icon: "upload-paperclip"
                  };
                  customToolbar[buttonIndex - 1] = {
                    ...button,
                    id: "upload-media",
                    title: (0, _discourseI18n.i18n)("agc.composer.insert_media"),
                    icon: "far-image"
                  };
                } else {
                  customToolbar[buttonIndex] = button;
                }
              }
            });
          });
          toolbar.groups = [{
            group: "streamline-posting",
            buttons: customToolbar
          }];
          toolbar.addButton({
            id: "poll",
            group: "streamline-posting",
            icon: "chart-bar",
            label: null,
            preventFocus: true,
            trimLeading: true,
            title: "agc.composer.insert_poll",
            perform: toolbarEvent => {
              api.container.lookup("service:modal").show(_pollUiBuilder.default, {
                model: {
                  toolbarEvent
                }
              });
            }
          });
          return toolbar;
        }
      }, [["method", "toolbar", [(0, _decorators.default)()]]]));
    },
    _forceToolbar(api) {
      api.modifyClass("service:composer", {
        pluginId: `${PLUGIN_ID} _forceToolbar`,
        showToolbar: true,
        actions: {
          toggleToolbar() {
            // do nothing
          }
        }
      });
      api.modifyClass("component:composer-toggles", {
        pluginId: PLUGIN_ID,
        didInsertElement() {
          this._super(...arguments);
          document.querySelector(".composer-controls").classList.add("force-toolbar");
        }
      });
    },
    _setComposerFlag(api) {
      const other = this;
      api.customizeComposerText({
        saveLabel(model) {
          if (other._isOverridableAction(model.action)) {
            const isEditing = model.action === "edit";
            const isReplying = !!model?.reply && model.action !== "createTopic";
            const isCommenting = model.action === "reply" && !isReplying;
            if (isEditing) {
              return "agc.composer.edit";
            } else if (isReplying) {
              return "agc.composer.reply";
            } else if (isCommenting) {
              return "agc.composer.comment";
            }
            return "agc.composer.post";
          }
        }
      });
      api.modifyClass("component:composer-editor", {
        pluginId: PLUGIN_ID,
        didInsertElement() {
          this._super(...arguments);
          const classList = document.documentElement.classList;
          if (other._isOverridableAction(this.composer.model.action)) {
            classList.add("agc-streamline-posting");
            classList.add("simple-composer");
            this._updateComposerButtons();
          } else {
            classList.remove("simple-composer");
          }
        },
        _updateComposerButtons() {
          const cancelBtn = document.querySelector(".save-or-cancel .cancel");
          cancelBtn.innerHTML = (0, _discourseI18n.i18n)("agc.composer.cancel");
          const uploadBtnSelector = this.site.mobileView ? ".submit-panel .mobile-file-upload" : ".mobile-file-upload";
          const uploadBtn = document.querySelector(uploadBtnSelector);
          if (uploadBtn) {
            uploadBtn.innerHTML = (0, _discourseI18n.i18n)("agc.composer.upload");
          }
        }
      });
    },
    _updateComposerTitle(api) {
      const other = this;
      api.modifyClass("component:composer-action-title", dt7948.p({
        pluginId: PLUGIN_ID,
        siteSettings: (0, _service.service)(),
        actionTitle(opts, action) {
          if (other._isOverridableAction(action)) {
            return (0, _discourseI18n.i18n)(`agc.composer.${action === "privateMessage" ? "private_message_title" : "title"}`);
          } else {
            return this._super(...arguments);
          }
        }
      }, [["method", "actionTitle", [(0, _decorators.default)("options", "action")]]]));
    },
    _isOverridableAction(action) {
      return action === "createTopic" || action === "reply" || action === "privateMessage" || action === "edit";
    }
  };
});