define("discourse/plugins/discourse-global-communities/discourse/lib/agc-user-session", ["exports", "@ember/object", "rsvp", "discourse/lib/ajax", "discourse/lib/decorators", "discourse/mixins/singleton"], function (_exports, _object, _rsvp, _ajax, _decorators, _singleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AgcUserSession = _object.default.extend(dt7948.p({
    currentUser: null,
    setup(siteSettings, pluginApi) {
      this._siteSettings = siteSettings;
      // do not support anonymous users for now
      this.currentUser = pluginApi.getCurrentUser();
      if (!this.currentUser) {
        return;
      }
      return new _rsvp.Promise(resolve => {
        this.transitIdTimeoutMs = siteSettings.agc_analytics_transit_id_timeout * 60 * 1000;
        this._setupIdleInterrupts(pluginApi);
        this._syncUserSessionIds().then(() => {
          resolve({
            sessionId: this._sessionId(),
            transitId: this._transitId()
          });
        });
      });
    },
    sessionIds() {
      return new _rsvp.Promise(resolve => {
        if (!this._sessionId() || !this._transitId()) {
          this._syncUserSessionIds().then(() => {
            resolve({
              sessionId: this._sessionId(),
              transitId: this._transitId()
            });
          });
        } else {
          // this public function is called by analytics etc. which is a result
          // of user action, meaning the user is active once more
          this._resetTransitIdleTimeout();
          resolve({
            sessionId: this._sessionId(),
            transitId: this._transitId()
          });
        }
      });
    },
    clear() {
      this._setSessionId(null);
      this._setTransitId(null);
    },
    updateIds(sessionId, transitId) {
      this._setSessionId(sessionId);
      this._setTransitId(transitId);
      this._resetTransitIdleTimeout();
      if (typeof window.ReactNativeWebView !== "undefined" && this._siteSettings.agc_enable_session_update_post_message_events) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          discourse_event: "update_session_data",
          user_id: this.currentUser?.id,
          session_id: sessionId,
          transit_id: transitId
        }));
      }
    },
    _setupIdleInterrupts(pluginApi) {
      window.addEventListener("focus", this._debouncedReset);
      document?.addEventListener("scroll", this._debouncedReset);
      pluginApi.onAppEvent("page:changed", this._debouncedReset);
    },
    _sessionId() {
      return localStorage.agcUserSessionId;
    },
    _transitId() {
      return localStorage.agcUserTransitId;
    },
    _setSessionId(id) {
      // don't want to end up with the string "null"
      if (!id) {
        delete localStorage.agcUserSessionId;
        return;
      }
      localStorage.agcUserSessionId = id;
    },
    _setTransitId(id) {
      // don't want to end up with the string "null"
      if (!id) {
        delete localStorage.agcUserTransitId;
        return;
      }
      localStorage.agcUserTransitId = id;
    },
    _syncUserSessionIds() {
      let currentSessionData = {
        agc_transit_id: this._transitId(),
        agc_session_id: this._sessionId()
      };
      return (0, _ajax.ajax)("/agc-user-session.json", {
        type: "POST",
        data: currentSessionData
      }).then(resp => {
        this.updateIds(resp.session_id, resp.transit_id);
      });
    },
    _resetTransitIdleTimeout() {
      if (this._transitIdleTimeout) {
        window.clearTimeout(this._transitIdleTimeout);
      }
      this._transitIdleTimeout = window.setTimeout(() => this._expireTransitId(), this.transitIdTimeoutMs);
    },
    _debouncedReset() {
      this._resetTransitIdleTimeout();
    },
    // setting the transit ID to null will force the sync endpoint
    // to give us a new one
    _expireTransitId() {
      this._setTransitId(null);
      window.clearTimeout(this._transitIdleTimeout);
    }
  }, [["method", "_debouncedReset", [(0, _decorators.debounce)(300)]]]));
  AgcUserSession.reopenClass(_singleton.default);
  var _default = _exports.default = AgcUserSession;
});