define("discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/post-badges", ["exports", "discourse/components/topic-post-badges", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _topicPostBadges, _discourseI18n, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcTopicListPostBadges extends _topicPostBadges.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{~! no whitespace ~}}
        <span class="topic-post-badges">
          {{~#if this.displayUnreadPosts~}}
            &nbsp;<a
              href={{@url}}
              title={{i18n
                "agc.topic-list.unread_title"
                count=this.displayUnreadPosts
              }}
              class="badge badge-notification unread-posts"
            >
              {{i18n "agc.topic-list.unread" count=this.displayUnreadPosts}}
            </a>
          {{~/if~}}
    
          {{~#if @unseen~}}
            &nbsp;<a
              href={{@url}}
              title={{i18n "agc.topic-list.new_title"}}
              class="badge badge-notification new-posts new"
            >
              {{i18n "agc.topic-list.new"}}
            </a>
          {{~/if~}}
        </span>
        {{~! no whitespace ~}}
      
    */
    {
      "id": "j5DeFMbh",
      "block": "[[[10,1],[14,0,\"topic-post-badges\"],[12],[41,[30,0,[\"displayUnreadPosts\"]],[[[1,\" \"],[10,3],[15,6,[30,1]],[15,\"title\",[28,[32,0],[\"agc.topic-list.unread_title\"],[[\"count\"],[[30,0,[\"displayUnreadPosts\"]]]]]],[14,0,\"badge badge-notification unread-posts\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"agc.topic-list.unread\"],[[\"count\"],[[30,0,[\"displayUnreadPosts\"]]]]]],[1,\"\\n        \"],[13]],[]],null],[41,[30,2],[[[1,\" \"],[10,3],[15,6,[30,1]],[15,\"title\",[28,[32,0],[\"agc.topic-list.new_title\"],null]],[14,0,\"badge badge-notification new-posts new\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"agc.topic-list.new\"],null]],[1,\"\\n        \"],[13]],[]],null],[13]],[\"@url\",\"@unseen\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/post-badges.js",
      "scope": () => [_discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcTopicListPostBadges;
});