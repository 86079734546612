define("discourse/plugins/discourse-global-communities/discourse/components/bulk-posts-actions-bar", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/decorators", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-bulk-posts-actions-modal", "discourse/plugins/discourse-global-communities/discourse/components/post-selector", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _decorators, _discourseI18n, _agcBulkPostsActionsModal, _postSelector, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="bulk-posts-actions-bar">
    <DButton
      class="bulk-select btn-transparent"
      @action={{this.toggleBulkSelect}}
      @icon="list"
      @title="posts.bulk.toggle"
    />
    {{#if this.isBulkActionsEnabled}}
      <DButton
        class="bulk-actions"
        @action={{this.openBulkActionsModal}}
        @icon="gear"
      />
    {{/if}}
    {{#if this.isBulkSelectEnabled}}
      <DButton
        class="bulk-select-all"
        @action={{this.bulkSelectAll}}
        @label="posts.bulk.select_all"
      />
      <DButton
        class="bulk-clear-all"
        @action={{this.bulkClearAll}}
        @label="posts.bulk.clear_all"
      />
    {{/if}}
  </div>
  */
  {
    "id": "ISzZLYus",
    "block": "[[[10,0],[14,0,\"bulk-posts-actions-bar\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"bulk-select btn-transparent\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"toggleBulkSelect\"]],\"list\",\"posts.bulk.toggle\"]],null],[1,\"\\n\"],[41,[30,0,[\"isBulkActionsEnabled\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"bulk-actions\"]],[[\"@action\",\"@icon\"],[[30,0,[\"openBulkActionsModal\"]],\"gear\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"isBulkSelectEnabled\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"bulk-select-all\"]],[[\"@action\",\"@label\"],[[30,0,[\"bulkSelectAll\"]],\"posts.bulk.select_all\"]],null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"bulk-clear-all\"]],[[\"@action\",\"@label\"],[[30,0,[\"bulkClearAll\"]],\"posts.bulk.clear_all\"]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"d-button\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/bulk-posts-actions-bar.hbs",
    "isStrictMode": false
  });
  class BulkPostsActionsBar extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "agcPostBulkActions", [_service.service]))();
    #agcPostBulkActions = (() => (dt7948.i(this, "agcPostBulkActions"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isBulkSelectEnabled", [_tracking.tracked]))();
    #isBulkSelectEnabled = (() => (dt7948.i(this, "isBulkSelectEnabled"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "isBulkActionsEnabled", [_tracking.tracked]))();
    #isBulkActionsEnabled = (() => (dt7948.i(this, "isBulkActionsEnabled"), void 0))();
    element;
    constructor() {
      super(...arguments);
      this.allPostIds = this.args?.allPostIds ?? [];
      this.allPostIds = this.allPostIds.sort((postId1, postId2) => parseInt(postId1, 10) - parseInt(postId2, 10));
      this.isBulkSelectEnabled = false;
      this.isBulkActionsEnabled = false;
      this.agcPostBulkActions.setRenderPostSelector(false);
      this.appEvents.on("agc-custom:bulk-post-actions-select-post", this, this.handleSelectPost);
      this.appEvents.on("agc-custom:bulk-post-actions-deselect-post", this, this.handleDeselectPost);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("agc-custom:bulk-post-actions-select-post", this, this.handleSelectPost);
      this.appEvents.off("agc-custom:bulk-post-actions-deselect-post", this, this.handleDeselectPost);
      this.agcPostBulkActions.setRenderPostSelector(false);
    }
    handleSelectPost(event) {
      this.agcPostBulkActions.insertSelectedPostId(event.postId);
      this.isBulkActionsEnabled = true;
    }
    handleDeselectPost(event) {
      this.agcPostBulkActions.removeSelectedPostId(event.postId);
      this.isBulkActionsEnabled = this.agcPostBulkActions.getSelectedPostIds().size > 0;
    }
    setupScrollListener() {
      this.element = document.querySelector(".bulk-posts-actions-bar");
      this.elementOffset = this.element.getBoundingClientRect().top + window.scrollY;
      window.addEventListener("scroll", this.onScroll);
    }
    removeScrollListener() {
      this.element.classList.remove("sticky-scrolled");
      window.removeEventListener("scroll", this.onScroll);
    }
    onScroll() {
      if (!this.ticking) {
        requestAnimationFrame(this.updatePositioning);
      }
      this.ticking = true;
    }
    static #_7 = (() => dt7948.n(this.prototype, "onScroll", [_decorators.bind]))();
    updatePositioning() {
      this.element.classList.toggle("sticky-scrolled", window.scrollY >= this.elementOffset - 60);
      this.ticking = false;
    }
    static #_8 = (() => dt7948.n(this.prototype, "updatePositioning", [_decorators.bind]))();
    toggleBulkSelect() {
      this.appEvents.trigger("agc-custom:bulk-post-actions-toggle-render-post-selector");
      /* store the renderPostSelector state in agcPostBulkActions service to make */
      /* it accessible to the posts not loaded yet */
      this.agcPostBulkActions.toggleRenderPostSelector();
      this.isBulkSelectEnabled = !this.isBulkSelectEnabled;
      if (this.isBulkSelectEnabled) {
        this.setupScrollListener();
      } else {
        this.bulkClearAll();
        this.removeScrollListener();
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "toggleBulkSelect", [_object.action]))();
    openBulkActionsModal() {
      this.modal.show(_agcBulkPostsActionsModal.default, {
        model: {
          postIds: Array.from(this.agcPostBulkActions.getSelectedPostIds())
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "openBulkActionsModal", [_object.action]))();
    bulkSelectAll() {
      for (let postId of this.allPostIds) {
        if (this.agcPostBulkActions.getSelectedPostIds().size < _postSelector.MAX_SELECTED_POSTS_LIMIT) {
          this.appEvents.trigger(`agc-custom:bulk-post-actions-select-${postId}`);
          this.agcPostBulkActions.insertSelectedPostId(postId);
        } else {
          this.dialog.alert((0, _discourseI18n.i18n)("posts.select.warning", {
            limit: _postSelector.MAX_SELECTED_POSTS_LIMIT
          }));
          break;
        }
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "bulkSelectAll", [_object.action]))();
    bulkClearAll() {
      this.appEvents.trigger("agc-custom:bulk-post-actions-clear-selections");
      this.agcPostBulkActions.removeAllSelectedPostIds();
      this.isBulkActionsEnabled = false;
    }
    static #_12 = (() => dt7948.n(this.prototype, "bulkClearAll", [_object.action]))();
  }
  _exports.default = BulkPostsActionsBar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BulkPostsActionsBar);
});