define("discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/all-loaded", ["exports", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <h3>{{i18n "agc.topic-list.all-loaded"}}</h3>
  
  */
  {
    "id": "g4Vl4vTK",
    "block": "[[[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"agc.topic-list.all-loaded\"],null]],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/all-loaded.js",
    "scope": () => [_discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "all-loaded"));
});