define("discourse/plugins/discourse-global-communities/discourse/pre-initializers/agc-tests-site-filters", ["exports", "discourse/lib/environment", "discourse/models/site"], function (_exports, _environment, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-tests-site-filters",
    before: "map-routes",
    initialize() {
      if ((0, _environment.isTesting)()) {
        /** This is a hack that adds AGC's custom discovery filters
         * to the Site model in test env.
         * Adding the custom filters in a beforeEach hook in the
         * tests themselves doesn't work because the hooks are
         * called way too late after the routes are already
         * registered.
         * TODO: add support for this case in core.
         **/
        const filters = _site.default.currentProp("filters");
        ["following", "unanswered", "latesttopics"].forEach(f => {
          if (!filters.includes(f)) {
            filters.push(f);
          }
        });
      }
    }
  };
});