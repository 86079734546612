define("discourse/plugins/discourse-global-communities/discourse/components/tv-suggested-episodes", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/url", "discourse/mixins/scroll-top", "discourse/models/topic", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/index", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _ajax, _url, _scrollTop, _topic, _discourseI18n, _index, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TvSuggestedEpisodes extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "show", [_tracking.tracked], function () {
      return false;
    }))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "topics", [_tracking.tracked]))();
    #topics = (() => (dt7948.i(this, "topics"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showSeeAllLink", [_tracking.tracked], function () {
      return false;
    }))();
    #showSeeAllLink = (() => (dt7948.i(this, "showSeeAllLink"), void 0))();
    constructor() {
      super(...arguments);
      if (this.args.model.is_tv_episode) {
        this.#fetchNewEpisodes();
      }
    }
    async #fetchNewEpisodes() {
      const response = await (0, _ajax.ajax)("/tv/new.json");
      if (response.topics && response.topics.length) {
        this.show = true;
        this.topics = response.topics.map(topic => {
          return _topic.default.create(topic);
        });
        this.showSeeAllLink = response.topics.length > this.siteSettings.tv_carousel_tiles_per_page;
      }
    }
    clickSeeAll(e) {
      e.preventDefault();
      (0, _scrollTop.scrollTop)();
      _url.default.routeTo("/tv");
    }
    static #_5 = (() => dt7948.n(this.prototype, "clickSeeAll", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <div class="tv-watch-next">
            <div class="section">
              <div class="section-header">
                <h2 class="section-title">{{i18n "agc.tv.watch_next"}}</h2>
                {{#if this.showSeeAllLink}}
                  <a href {{on "click" this.clickSeeAll}} class="see-all">{{i18n
                      "agc.suggested_tv.see_all"
                    }}</a>
                {{/if}}
              </div>
    
              <Carousel
                @items={{this.topics}}
                @tileType="tv"
                @tilesPerPage={{4}}
                @registerAnalytics={{false}}
              />
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "5DK38ap+",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"tv-watch-next\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"section-header\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,0,\"section-title\"],[12],[1,[28,[32,0],[\"agc.tv.watch_next\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"showSeeAllLink\"]],[[[1,\"              \"],[11,3],[24,6,\"\"],[24,0,\"see-all\"],[4,[32,1],[\"click\",[30,0,[\"clickSeeAll\"]]],null],[12],[1,[28,[32,0],[\"agc.suggested_tv.see_all\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n\\n          \"],[8,[32,2],null,[[\"@items\",\"@tileType\",\"@tilesPerPage\",\"@registerAnalytics\"],[[30,0,[\"topics\"]],\"tv\",4,false]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/tv-suggested-episodes.js",
      "scope": () => [_discourseI18n.i18n, _modifier.on, _index.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TvSuggestedEpisodes;
});