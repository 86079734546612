define("discourse/plugins/discourse-global-communities/discourse/raw-views/agc-topic-list-actions", ["exports", "@ember/object", "discourse/lib/raw-render-glimmer", "discourse/plugins/discourse-global-communities/discourse/components/agc-topic-list-actions", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _object, _rawRenderGlimmer, _agcTopicListActions, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO (discourse.hbr-topic-list-overrides): Remove this file once the Glimmer topic list is enabled in prod

  class _default extends _object.default {
    get html() {
      return (0, _rawRenderGlimmer.default)(this, "div.agc-topic-list-actions", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
      /*
        <AgcTopicListActions @topic={{@data.topic}} />
      */
      {
        "id": "aa7BKjvn",
        "block": "[[[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[\"@data\"],false,[]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/raw-views/agc-topic-list-actions.js",
        "scope": () => [_agcTopicListActions.default],
        "isStrictMode": true
      }), (0, _templateOnly.default)(undefined, undefined)), {
        topic: this.topic
      });
    }
  }
  _exports.default = _default;
});