define("discourse/plugins/discourse-global-communities/discourse/initializers/topic-list", ["exports", "@ember/object", "@ember/service", "@ember/template", "@ember-decorators/object", "jquery", "discourse/components/topic-list/latest-topic-list-item", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse/lib/deprecated", "discourse/lib/formatter", "discourse/lib/plugin-api", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/bottom-row", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/by-line", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/top-row", "discourse/plugins/discourse-global-communities/discourse/helpers/formatted", "discourse/plugins/discourse-global-communities/discourse/lib/agc-search", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _object, _service, _template, _object2, _jquery, _latestTopicListItem, _ajax, _ajaxError, _decorators, _deprecated, _formatter, _pluginApi, _discourseI18n, _bottomRow, _byLine, _topRow, _formatted, _agcSearch, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/jquery-ember-run */

  const STARTED = "started";
  const ENDED = "ended";
  var _default = _exports.default = {
    name: "agc-topic-list",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.39.0", api => {
        customizeTopicList(api);
        const siteSettings = api.container.lookup("service:site-settings");
        if (siteSettings.glimmer_topic_list_mode !== "enabled") {
          const silencedKey = siteSettings.glimmer_topic_list_mode === "auto" && "discourse.hbr-topic-list-overrides";
          (0, _deprecated.withSilencedDeprecations)(silencedKey, () => customizeWidgetTopicList(api));
        }
      });
    }
  };
  function customizeTopicList(api) {
    // AGC feeds use the same layout for the cards in desktop and mobile.
    // Desktop is easier to customize to AGC needs using the topic list Glimmer API
    api.registerValueTransformer("topic-list-item-mobile-layout", () => false);
    api.registerValueTransformer("topic-list-columns", _ref => {
      let {
        value: columns
      } = _ref;
      // AGC feeds use the same layout for the categories page topics, which they use for as their home page and the
      // discovery pages (latest, top, hot, tags, etc).
      // To keep just one set of customizations for all these pages, we'll customize the layout for the topics in the
      // category page because the markup  we have in core is closer to what they need.
      // In order to reuse this in the discovery pages, we'll remove all the columns the 'topic' column and override it
      // with the custom layout by reusing the LatestTopicListItem component
      ["posters", "replies", "likes", "op-likes", "views", "activity"].forEach(columnName => columns.delete(columnName));
      columns.replace("topic", {
        item: _latestTopicListItem.default
      });
      return columns;
    });
    api.registerValueTransformer("latest-topic-list-item-class", _ref2 => {
      let {
        value: extraClasses
      } = _ref2;
      extraClasses.push("topic-list-item");
      return extraClasses;
    });
    // now we customize the topic card displayed in these pages
    api.renderInOutlet("latest-topic-list-item-topic-poster", _byLine.default);
    api.renderInOutlet("latest-topic-list-item-main-link-top-row", _topRow.default);
    api.renderInOutlet("latest-topic-list-item-main-link-bottom-row", _bottomRow.default);
    api.renderInOutlet("latest-topic-list-item-topic-stats", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{~! no whitespace ~}}
      
    */
    {
      "id": "7JwFjtB9",
      "block": "[[],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/initializers/topic-list.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  }
  function customizeWidgetTopicList(api) {
    api.modifyClass("component:topic-list-item", Superclass => class extends Superclass {
      reRenderTopicListItem() {
        this.renderTopicListItem();
      }
      static #_ = (() => dt7948.n(this.prototype, "reRenderTopicListItem", [(0, _object2.observes)("topic.bookmarked")]))();
      toggleDeletedAddClass() {
        this.renderTopicListItem();
        this.element.classList.toggle("-deleted", this.topic.deleted_at);
      }
      static #_2 = (() => dt7948.n(this.prototype, "toggleDeletedAddClass", [(0, _object2.observes)("topic.deleted_at")]))();
    });
    api.modifyClass("component:topic-list-item", Superclass => class extends Superclass {
      didInsertElement() {
        super.didInsertElement(...arguments);
        if (this.siteSettings.zoom_enabled) {
          const webinar = this.get("topic.webinar");
          if (webinar) {
            this.setProperties({
              webinar,
              loading: false,
              canRegister: this.registrationOpen(webinar) && !this.isHost(webinar) && !this.isPanelist(webinar) && !this.isAttendee(webinar)
            });
            (0, _jquery.default)(this.element).on("click", ".webinar-register-button", () => {
              this.toggleRegistration(true);
            });
            (0, _jquery.default)(this.element).on("click", ".webinar-unregister-button", () => {
              this.toggleRegistration(false);
            });
            (0, _jquery.default)(this.element).on("click", ".webinar-join-sdk", () => {
              this.joinSDK();
            });
          }
        }
      }
      webinarStarted(webinar) {
        if (webinar.status) {
          const beforeStart = this.siteSettings.zoom_join_x_mins_before_start;
          if (webinar.status === STARTED) {
            if (!beforeStart) {
              return true;
            }
            const newStartTime = moment(webinar.starts_at).subtract(beforeStart, "minutes");
            if (newStartTime.isBefore(moment())) {
              return true;
            }
          }
          return false;
        }
      }
      static #_ = (() => dt7948.n(this.prototype, "webinarStarted", [(0, _decorators.default)("topic.webinar")]))();
      webinarEnded(status) {
        return status === ENDED;
      }
      static #_2 = (() => dt7948.n(this.prototype, "webinarEnded", [(0, _decorators.default)("topic.webinar.status")]))();
      webinarTime(webinar) {
        if (webinar.ends_at === null) {
          return moment(webinar.starts_at).format((0, _discourseI18n.i18n)("dates.full_with_year_no_time"));
        }
        return (0, _formatted.formattedSchedule)(webinar.starts_at, webinar.ends_at);
      }
      static #_3 = (() => dt7948.n(this.prototype, "webinarTime", [(0, _decorators.default)("topic.webinar")]))();
      registrationOpen(webinar) {
        const w = webinar || this.webinar;
        if (w && w.starts_at && w.ends_at) {
          return moment(w.starts_at).isAfter(moment());
        }
        return false;
      }
      isAttendee(webinar) {
        const w = webinar || this.webinar;
        if (w && w.attendees) {
          for (let attendee of w.attendees) {
            if (attendee.id === this.currentUser.id) {
              return true;
            }
          }
        }
        return false;
      }
      isHost(webinar) {
        const w = webinar || this.webinar;
        if (w && w.host) {
          return this.currentUser.id === w.host.id;
        }
        return false;
      }
      isPanelist(webinar) {
        const w = webinar || this.webinar;
        if (w.panelists) {
          for (let panelist of w.panelists) {
            if (panelist.id === this.currentUser.id) {
              return true;
            }
          }
        }
        return false;
      }
      toggleRegistration(registering) {
        const method = registering ? "PUT" : "DELETE";
        return (0, _ajax.ajax)(`/zoom/webinars/${this.webinar.id}/attendees/${this.currentUser.username}.json`, {
          type: method
        }).then(() => {
          this.setProperties({
            canRegister: !registering,
            isAttendee: registering,
            loading: false
          });
          this.renderTopicListItem();
        }).catch(function (error) {
          (0, _ajaxError.popupAjaxError)(error);
        });
      }
      joinSDK() {
        const url = this.siteSettings.zoom_enable_sdk_fallback ? `/zoom/webinars/${this.webinar.id}/sdk?fallback=1` : `/zoom/webinars/${this.webinar.id}/sdk`;
        if (this.registered) {
          window.location.href = url;
        } else {
          this.toggleRegistration(true).then(() => {
            window.location.href = url;
          });
        }
      }
      willDestroyElement() {
        super.willDestroyElement(...arguments);
        if (this.siteSettings.zoom_enabled) {
          (0, _jquery.default)(this.element).off("click", ".webinar-register-button");
          (0, _jquery.default)(this.element).off("click", ".webinar-unregister-button");
          (0, _jquery.default)(this.element).off("click", ".webinar-join-sdk");
        }
      }
    });
    api.modifyClass("component:topic-list-item", Superclass => class extends Superclass {
      static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
      #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
      amazonianGroupName() {
        return this.siteSettings.amazonian_group_name;
      }
      static #_2 = (() => dt7948.n(this.prototype, "amazonianGroupName", [(0, _decorators.default)("siteSettings.amazonian_group_name")]))();
    });
    api.modifyClass("component:topic-list-item", Superclass => class extends Superclass {
      static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
      #site = (() => (dt7948.i(this, "site"), void 0))();
      static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
      #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
      init() {
        if (this.topic.thumbnails) {
          const sizedThumbnail = this.topic.thumbnails.filter(t => {
            if (!t.url) {
              return false;
            }
            if (this.site.mobileView) {
              return t.max_width === 600 && t.max_height === 300;
            } else {
              return t.max_width === 600 && t.max_height === 600;
            }
          });
          const topicThumbnail = sizedThumbnail.lastObject ? sizedThumbnail.lastObject : this.topic.thumbnails[0];
          this.topic.set("thumbnail_url", topicThumbnail.url);
          if (topicThumbnail.width && topicThumbnail.height) {
            this.topic.set("thumbnail_aspect_ratio", (0, _template.htmlSafe)(`--aspect-ratio: ${topicThumbnail.width}/${topicThumbnail.height}; --aspect-ratio-height: auto;`));
          }
        }
        // Add default thumbnail for webinars
        if (!this.topic.thumbnails && this.topic.gc_content_type && this.topic.gc_content_type.replace(/\s/g, "").toLowerCase() === "tv") {
          this.topic.set("thumbnail_url", this.siteSettings.broadcast_box_background);
        }
        super.init(...arguments);
      }
      showAGCPosters(isPrivateMessage) {
        return isPrivateMessage && this.get("showPosters");
      }
      static #_3 = (() => dt7948.n(this.prototype, "showAGCPosters", [(0, _decorators.default)("topic.isPrivateMessage")]))();
      tagsOrContentType(contentType, tags) {
        return contentType || tags && tags.length > 0;
      }
      static #_4 = (() => dt7948.n(this.prototype, "tagsOrContentType", [(0, _decorators.default)("topic.gc_content_type", "topic.visibleListTags")]))();
      hasCategoryExpertFields(groups, needsApproval, isQuestion) {
        return groups?.length || needsApproval || isQuestion;
      }
      static #_5 = (() => dt7948.n(this.prototype, "hasCategoryExpertFields", [(0, _decorators.default)("topic.expert_post_group_names", "topic.needs_category_expert_post_approval", "topic.is_category_expert_question")]))();
      relativeCreatedAt(date) {
        if (!this.siteSettings.topic_relative_creation_date) {
          return;
        }
        return `${(0, _formatter.relativeAge)(date, {
          format: "tiny",
          addAgo: true
        })}`;
      }
      static #_6 = (() => dt7948.n(this.prototype, "relativeCreatedAt", [(0, _decorators.default)("topic.createdAt")]))();
      displayCategoryExpertFields(hasCategoryExpertFields, expertPostGroupNames, isCategoryExpertQuestion) {
        return hasCategoryExpertFields && (expertPostGroupNames?.length || isCategoryExpertQuestion);
      }
      static #_7 = (() => dt7948.n(this.prototype, "displayCategoryExpertFields", [(0, _decorators.default)("hasCategoryExpertFields", "topic.expert_post_group_names", "topic.is_category_expert_question")]))();
      contentTypeSearchLink(gcContentType) {
        const searchTerm = (0, _agcSearch.contentTypeToSearchFilter)(gcContentType);
        return `/search?q=${searchTerm}`;
      }
      static #_8 = (() => dt7948.n(this.prototype, "contentTypeSearchLink", [(0, _decorators.default)("topic.gc_content_type")]))();
      avatarFlair(flairUrl, flairName) {
        if (!flairName) {
          return false;
        }
        return flairUrl && flairUrl.includes("/") ? flairUrl : undefined;
      }
      static #_9 = (() => dt7948.n(this.prototype, "avatarFlair", [(0, _decorators.default)("topic.posters.0.user.flair_url", "topic.posters.0.user.flair_name")]))();
      get isAmazonian() {
        return this.siteSettings.amazonian_group_name && this.topic?.posters?.[0]?.user?.flair_name === this.siteSettings.amazonian_group_name;
      }
      static #_10 = (() => dt7948.n(this.prototype, "isAmazonian", [(0, _object.computed)("topic.posters.0.user.flair_name")]))();
    });
  }
});