define("discourse/plugins/discourse-global-communities/discourse/widgets/see-amazon-comments-button", ["discourse/lib/url", "discourse/widgets/widget", "discourse-i18n"], function (_url, _widget, _discourseI18n) {
  "use strict";

  (0, _widget.createWidget)("see-amazon-comments-button", {
    tagName: "",
    buildKey: () => "see-amazon-comments",
    seeAmazonComments() {
      const postStream = this.attrs.topic.postStream;
      postStream.cancelFilter();
      postStream.refresh({
        filter: "amazon_responses"
      }).then(() => {
        this.appEvents.trigger("enhanced_user_analytics:event", "page_view", {}, {
          clickRef: "CTA_amazon_responses"
        });
        _url.default.jumpToPost("1", {
          anchor: "comments-container"
        });
      });
    },
    show() {
      let amazonComments = 0;
      if (this.attrs.topic.expert_post_group_count && this.attrs.topic.expert_post_group_count["Amazonians"] && this.attrs.topic.postStream.streamFilters.filter !== "amazon_responses") {
        amazonComments = this.attrs.topic.expert_post_group_count["Amazonians"];
      }
      return amazonComments > 0;
    },
    html() {
      if (this.show()) {
        return this.attach("button", {
          translatedLabel: (0, _discourseI18n.i18n)("agc.responses_filter.see_amazon_comments"),
          action: "seeAmazonComments",
          className: "see-amazon-comments"
        });
      }
    }
  });
});