define("discourse/plugins/discourse-global-communities/discourse/feature-flags/topic-show-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicExcerptShowMoreFeatureFlag {
    static enabledKey = "topic_show_more_enabled";
    static className = "topic-list-item-read-more-enabled";
  }
  _exports.default = TopicExcerptShowMoreFeatureFlag;
});