define("discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/top-row", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/template", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-show-more/topic-list-item-expandable-excerpt", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/webinar-list-item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _template, _topicListItemExpandableExcerpt, _webinarListItem, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcTopicListTopicTopRow extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get topic() {
      return this.args.outletArgs.topic;
    }
    get thumbnail() {
      if (this.topic.thumbnails) {
        if (this.topic.gif_thumbnail) {
          return this.topic.gif_thumbnail;
        }
        const sizedThumbnail = this.topic.thumbnails.filter(t => {
          if (!t.url) {
            return false;
          }
          if (this.site.mobileView) {
            return t.max_width === 600 && t.max_height === 300;
          } else {
            return t.max_width === 600 && t.max_height === 600;
          }
        });
        return sizedThumbnail.at(-1) ? sizedThumbnail.at(-1) : this.topic.thumbnails[0];
      }
      // Add default thumbnail for webinars
      if (!this.topic.thumbnails && this.topic.gc_content_type && this.topic.gc_content_type.replace(/\s/g, "").toLowerCase() === "tv") {
        return this.siteSettings.broadcast_box_background;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "thumbnail", [_tracking.cached]))();
    get thumbnailStyle() {
      return (0, _template.htmlSafe)(`background: url(${this.thumbnail.url})`);
    }
    get thumbnailAspectRatio() {
      return this.thumbnail.width && this.thumbnail.height ? (0, _template.htmlSafe)(`--aspect-ratio: ${this.thumbnail.width}/${this.thumbnail.height}; --aspect-ratio-height: auto;`) : null;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.thumbnail}}
          {{! template-lint-disable no-invalid-link-text }}
          <a
            href={{this.topic.lastUnreadUrl}}
            style={{this.thumbnailStyle}}
            class="preview-image"
          > </a>
        {{/if}}
        {{yield}}
        {{#if this.topic.escapedExcerpt}}
          <TopicListItemExpandableExcerpt @topic={{this.topic}} />
        {{/if}}
        {{#if this.topic.webinar}}
          <WebinarListItem @webinar={{this.topic.webinar}} />
        {{/if}}
      
    */
    {
      "id": "z9c5svc3",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"thumbnail\"]],[[[1,\"      \"],[10,3],[15,6,[30,0,[\"topic\",\"lastUnreadUrl\"]]],[15,5,[30,0,[\"thumbnailStyle\"]]],[14,0,\"preview-image\"],[12],[1,\" \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,1,null],[1,\"\\n\"],[41,[30,0,[\"topic\",\"escapedExcerpt\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@topic\"],[[30,0,[\"topic\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"topic\",\"webinar\"]],[[[1,\"      \"],[8,[32,1],null,[[\"@webinar\"],[[30,0,[\"topic\",\"webinar\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/top-row.js",
      "scope": () => [_topicListItemExpandableExcerpt.default, _webinarListItem.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcTopicListTopicTopRow;
});