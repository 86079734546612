define("discourse/plugins/discourse-global-communities/discourse/controllers/members", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/utils", "discourse/lib/ajax", "discourse/lib/decorators", "discourse-i18n"], function (_exports, _controller, _object, _computed, _runloop, _utils, _ajax, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_SEARCH_LENGTH = 2;
  const ALL_DSPS = "all";
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    guideCategoryId: ALL_DSPS,
    searchTerm: null,
    loading: false,
    canLoadMore: true,
    stations: null,
    locations: null,
    filters: null,
    queryParams: ["station"],
    filterGroups: (0, _computed.readOnly)("model.extras.filter_groups"),
    selectedFilters: (0, _computed.readOnly)("model.extras.selected_filters"),
    categoryGuideFilterOptions(guideCategories) {
      if (!this.siteSettings.enable_category_experts || !guideCategories) {
        return false;
      }
      const options = [{
        value: ALL_DSPS,
        name: (0, _discourseI18n.i18n)("discourse_gc.no_category_guide")
      }];
      const skipAddGuidesToName = this.siteSettings.members_skip_adding_guides_to_categories.split("|").map(v => parseInt(v, 10));
      return options.concat(guideCategories.map(value => {
        return {
          value: value.id,
          name: skipAddGuidesToName.includes(value.id) ? value.name : (0, _discourseI18n.i18n)("discourse_gc.category_guide", {
            category_name: value.name
          })
        };
      }));
    },
    load() {
      let {
        resetPageNumber = false,
        search = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const params = {};
      if (this.siteSettings.enable_category_experts) {
        params.guideCategoryId = this.guideCategoryId;
      }
      const term = this.searchTerm || "";
      if (resetPageNumber) {
        this.set("page", 0);
      }
      params.page = this.page;
      if (search && term.length < MIN_SEARCH_LENGTH && term.length > 0) {
        return;
      }
      if (term.length >= MIN_SEARCH_LENGTH) {
        params.term = term;
      }
      if (!(0, _utils.isEmpty)(this.filterParams)) {
        params.filters = this.filterParams;
      }
      this.set("loading", true);
      (0, _ajax.ajax)("/members.json", {
        data: params
      }).then(json => {
        if (resetPageNumber) {
          this.set("model.members", json.members);
        } else {
          this.model.members.pushObjects(json.members);
        }
        this.set("canLoadMore", json.members.length === 24);
        this.set("loading", false);
      });
    },
    setLoadMore() {
      const members = this.get("model.members");
      this.set("canLoadMore", members.length === 24);
    },
    onCategoryGuideFilterChange(val) {
      this.set("guideCategoryId", val);
      this.load({
        resetPageNumber: true
      });
    },
    loadMore() {
      this.incrementProperty("page");
      this.load();
    },
    applyFilters(filters) {
      const filterParams = {};
      filters.forEach(filter => {
        if (filterParams[filter.type]) {
          filterParams[filter.type].push(filter.value);
        } else {
          filterParams[filter.type] = [filter.value];
        }
      });
      this.setProperties({
        filterParams
      });
      this.load({
        resetPageNumber: true
      });
    },
    searchTermChanged() {
      (0, _runloop.debounce)(this, this.load, {
        resetPageNumber: true,
        search: true
      }, 250);
    }
  }, [["method", "categoryGuideFilterOptions", [(0, _decorators.default)("model.extras.guide_categories")]], ["method", "setLoadMore", [(0, _decorators.observes)("model.members"), (0, _decorators.on)("didInsertElement")]], ["method", "onCategoryGuideFilterChange", [_object.action]], ["method", "loadMore", [_object.action]], ["method", "applyFilters", [_object.action]], ["method", "searchTermChanged", [_object.action]]]));
});