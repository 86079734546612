define("discourse/plugins/discourse-global-communities/discourse/controllers/connect", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "@ember/utils", "rsvp", "discourse/lib/ajax-error", "discourse/lib/debounce", "discourse/lib/environment", "discourse-i18n"], function (_exports, _tracking, _controller, _object, _service, _utils, _rsvp, _ajaxError, _debounce, _environment, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VIEW_TYPES = ["owner", "joined", "not_member"];
  class ConnectController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "circlesIndex", [_controller.inject]))();
    #circlesIndex = (() => (dt7948.i(this, "circlesIndex"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "filter", [_tracking.tracked], function () {
      return "";
    }))();
    #filter = (() => (dt7948.i(this, "filter"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "data", [_tracking.tracked], function () {
      return {};
    }))();
    #data = (() => (dt7948.i(this, "data"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    queryParams = ["filter"];
    get views() {
      return Object.entries(this.data).map(_ref => {
        let [name, data] = _ref;
        return new ViewSection(name, data);
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "views", [(0, _object.computed)("data")]))();
    get showNewCircleButton() {
      return this.currentUser?.can_create_circles;
    }
    get showCreateCallToAction() {
      return this.views.length === 1 && this.views[0].name === "not_member";
    }
    async load() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const {
        filter
      } = params;
      this.isLoading = true;
      const pageSize = this.site.mobileView ? 3 : 6;
      const sectionSize = this.site.mobileView ? 2 : 3;
      try {
        const fetchSectionsData = VIEW_TYPES.map(async name => {
          const fetchParams = {
            type: name,
            filter,
            page_size: pageSize
          };
          const fetchedData = await this.store.findAll("circle", fetchParams);
          return [name, fetchedData];
        });
        const data = await _rsvp.Promise.all(fetchSectionsData);
        const sections = data.filter(
        // do not display the sections owned and joined if there are no circles
        // or no sections at all if there filter is non-empty and there are no
        // results
        _ref2 => {
          let [name, sectionData] = _ref2;
          return name === "not_member" && (0, _utils.isEmpty)(filter) || sectionData?.length > 0;
        });

        // per design spec on mobile two cards should be displayed per section
        // unless at least one section was empty. in this case the not_member
        // section should must display three items. on desktop we toggle between
        // six and three items.
        const result = sections.map(_ref3 => {
          let [name, sectionData] = _ref3;
          const sliceSize = sections.length < VIEW_TYPES.length && name === "not_member" ? pageSize : sectionSize;
          sectionData.set("content", sectionData.content.slice(0, sliceSize));
          return [name, sectionData];
        });
        this.data = Object.fromEntries(result);
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      } finally {
        this.isLoading = false;
      }
    }
    get notFound() {
      return this.views.length === 0 && !(0, _utils.isEmpty)(this.filter);
    }
    static #_9 = (() => dt7948.n(this.prototype, "notFound", [(0, _object.computed)("views", "filter")]))();
    onFilterChanged(filter) {
      (0, _debounce.default)(this, this.#debouncedFilter, filter, _environment.INPUT_DELAY);
    }
    static #_10 = (() => dt7948.n(this.prototype, "onFilterChanged", [_object.action]))();
    new() {
      this.router.transitionTo("circles.new");
    }
    static #_11 = (() => dt7948.n(this.prototype, "new", [_object.action]))();
    viewAll(type) {
      this.router.transitionTo("circles.index", {
        queryParams: {
          type
        }
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "viewAll", [_object.action]))();
    #debouncedFilter(filter) {
      this.filter = filter;
    }
  }
  _exports.default = ConnectController;
  class ViewSection {
    #name = null;
    #data = null;
    constructor(name, data) {
      this.#name = name;
      this.#data = data;
    }
    get name() {
      return this.#name;
    }
    get circles() {
      return this.#data;
    }
    get title() {
      return (0, _discourseI18n.i18n)(`discourse_circles.index.types.${this.name}`);
    }
  }
});