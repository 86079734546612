define("discourse/plugins/discourse-global-communities/discourse/initializers/agc", ["exports", "@ember/runloop", "@ember/service", "@ember/string", "jquery", "discourse/lib/decorators", "discourse/lib/get-url", "discourse/lib/notification-levels", "discourse/lib/plugin-api", "discourse/lib/render-tag", "discourse/models/nav-item", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/helpers/sort-user-dropdown", "discourse/plugins/discourse-global-communities/discourse/helpers/urlstrip", "discourse/plugins/discourse-global-communities/discourse/lib/agc-search"], function (_exports, _runloop, _service, _string, _jquery, _decorators, _getUrl, _notificationLevels, _pluginApi, _renderTag, _navItem, _discourseI18n, _sortUserDropdown, _urlstrip, _agcSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "agc-base-component";
  var _default = _exports.default = {
    name: "agc-base",
    _urlToCustomCategory(router, category, params, excludedParams) {
      let queryParams = router.currentRoute.queryParams;
      (excludedParams || []).forEach(param => {
        delete queryParams[param];
      });
      queryParams = Object.assign(queryParams, params);
      return router.urlFor(router.currentRouteName, category, {
        queryParams
      });
    },
    initialize(container) {
      const htmlEl = document.documentElement;
      htmlEl.classList.add("not-amzl");

      // Prevent right-click menu on videos
      (0, _jquery.default)("body").on("contextmenu", "video", function () {
        return false;
      });
      const site = container.lookup("service:site");
      if (site && site.mobileView) {
        window.addEventListener("beforeinstallprompt", e => {
          // Prevent mini-infobar from showing on mobile
          // Ignite uses an app prompt instead
          e.preventDefault();
        });
      }
      (0, _renderTag.replaceTagRenderer)((tag, params) => {
        let defaultTag = (0, _renderTag.defaultRenderTag)(tag, params);
        if (params.includeAgcAnalyticsRef) {
          const tmp = document.createElement("div");
          tmp.innerHTML = defaultTag;
          const tagElement = tmp.querySelector("a");
          const tagName = tagElement.dataset.tagName.toLowerCase();
          tagElement.setAttribute("data-agc-analytics-ref", `nav_sidebar_popular_tag_${tagName}`);
          defaultTag = tmp.innerHTML;
        }
        return defaultTag;
      });
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        (0, _navItem.customNavItemHref)(navItem => {
          const name = navItem.name;
          if (name === "top" || name === "latest") {
            const router = container.lookup("service:router");
            const currentRoute = router.currentRoute;
            const category = currentRoute.attributes.category;
            if (category) {
              const queryParams = currentRoute.queryParams;
              queryParams.solved = null;
              queryParams.state = null;
              return router.urlFor(`discovery.${name}Category`, category, {
                queryParams
              });
            }
          }
          return null;
        });

        // Strip urls from suggested topics on mobile
        api.modifyClass("component:basic-topic-list", {
          pluginId: PLUGIN_ID,
          messageBus: (0, _service.service)(),
          didInsertElement() {
            this._super(...arguments);
            this.topics.forEach(topic => {
              const includeUnreadIndicator = typeof topic.unread_by_group_member !== "undefined";
              if (includeUnreadIndicator) {
                const unreadIndicatorChannel = `/private-messages/unread-indicator/${topic.id}`;
                this.messageBus.subscribe(unreadIndicatorChannel, data => {
                  const nodeClassList = document.querySelector(`.indicator-topic-${data.topic_id}`).classList;
                  nodeClassList.toggle("read", !data.show_indicator);
                });
              }
              if (topic.excerpt?.length) {
                let stripped = (0, _urlstrip.strip)(topic.excerpt);
                if (stripped.string?.length) {
                  topic.set("excerpt", stripped.string);
                }
              }
            });
          }
        });
        api.modifyClass("component:search-result-entry", dt7948.p({
          pluginId: PLUGIN_ID,
          contentTypeSearchLink(gcContentType) {
            const searchTerm = (0, _agcSearch.contentTypeToSearchFilter)(gcContentType);
            return `/search?q=${searchTerm}`;
          }
        }, [["method", "contentTypeSearchLink", [(0, _decorators.default)("post.topic.gc_content_type")]]]));
        api.modifyClass("controller:user", dt7948.p({
          pluginId: PLUGIN_ID,
          pronoun() {
            const field = this.publicUserFields && this.publicUserFields.find(row => row.field.name.toLowerCase() === "pronoun");
            return field ? field.value : null;
          },
          usernameWithPronoun(username, pronoun) {
            if (pronoun) {
              return `${username} (${pronoun})`;
            }
            return username;
          },
          nameWithPronoun(name, pronoun) {
            if (pronoun) {
              return `${name} (${pronoun})`;
            }
            return name;
          }
        }, [["method", "pronoun", [(0, _decorators.default)("model.user_fields.@each.value")]], ["method", "usernameWithPronoun", [(0, _decorators.default)("model.username", "pronoun")]], ["method", "nameWithPronoun", [(0, _decorators.default)("model.name", "pronoun")]]]));
        api.modifyClass("component:categories-topic-list", {
          pluginId: PLUGIN_ID,
          session: (0, _service.service)(),
          didInsertElement() {
            this._super(...arguments);
            const debouncedScroll = () => (0, _runloop.debounce)(this, this.saveScrollPosition, 100);
            (0, _jquery.default)(window).bind("scroll.categories-topic-list", debouncedScroll);
            const scrollTo = this.session.get("categoryTopicListScrollPosition");
            if (scrollTo && scrollTo >= 0) {
              (0, _runloop.schedule)("afterRender", () => (0, _jquery.default)(window).scrollTop(scrollTo));
            }
          },
          willDestroyElement() {
            this._super(...arguments);
            (0, _jquery.default)(window).unbind("scroll.categories-topic-list");
          },
          saveScrollPosition() {
            this.session.set("categoryTopicListScrollPosition", (0, _jquery.default)(window).scrollTop());
          }
        });
        api.modifyClass("component:user-fields/dropdown", dt7948.p({
          pluginId: PLUGIN_ID,
          sortedOptions(opts) {
            if (this.field.name === "DSP Since") {
              return opts.reverse();
            }

            // sort options for Station Location and Station custom user fields
            if (!["Station Location", "Station"].includes(this.field.name)) {
              return this.field.options;
            }
            return (0, _sortUserDropdown.default)(opts);
          },
          translatedName(name) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.name`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope);
            } else {
              return name;
            }
          },
          translatedDescription(name, description) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.description`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope, {
                basePath: (0, _getUrl.default)("")
              });
            } else {
              return description;
            }
          }
        }, [["method", "sortedOptions", [(0, _decorators.default)("field.options")]], ["method", "translatedName", [(0, _decorators.default)("field.name")]], ["method", "translatedDescription", [(0, _decorators.default)("field.name", "field.description")]]]));
        api.modifyClass("component:user-fields/text", dt7948.p({
          pluginId: PLUGIN_ID,
          translatedDescription(name, description) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.description`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope, {
                basePath: (0, _getUrl.default)("")
              });
            } else {
              return description;
            }
          },
          translatedName(name) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.name`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope);
            } else {
              return name;
            }
          }
        }, [["method", "translatedDescription", [(0, _decorators.default)("field.name", "field.description")]], ["method", "translatedName", [(0, _decorators.default)("field.name")]]]));
        api.modifyClass("component:user-fields/confirm", dt7948.p({
          pluginId: PLUGIN_ID,
          translatedDescription(name, description) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.description`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope, {
                basePath: (0, _getUrl.default)("")
              });
            } else {
              return description;
            }
          },
          translatedName(name) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.name`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope);
            } else {
              return name;
            }
          }
        }, [["method", "translatedDescription", [(0, _decorators.default)("field.name", "field.description")]], ["method", "translatedName", [(0, _decorators.default)("field.name")]]]));

        // move watching_first_post to top of the list
        api.modifyClass("component:notifications-button", {
          pluginId: PLUGIN_ID,
          agcTopicListWatching: (0, _service.service)(),
          currentUser: (0, _service.service)(),
          siteSettings: (0, _service.service)(),
          didInsertElement() {
            this._super(...arguments);

            // We want to check if the current user is the author of the first post and if so,
            // we add a class to the dropdown to hide it. It's a hacky way to hide, but works.
            if (this.currentUser?.id === this.topic?.user_id) {
              this.element.classList.add("hidden");
            }
            const reorderedContent = this.content.sort(a => {
              if (a.id === _notificationLevels.NotificationLevels.WATCHING_FIRST_POST) {
                return -1;
              }
              return 0;
            });
            this.set("content", reorderedContent);
          },
          onOpen() {
            // We show a button with bell icon and either
            // `Notify` or `Notified`, depending on topic tracking state. Clicking toggles
            // between regular and watching states
            const watchingId = this.content.find(notification => notification.key === "watching").id;
            const regularId = this.content.find(notification => notification.key === "regular").id;
            const newOption = this.value !== watchingId ? watchingId : regularId;
            this.agcWatchingService.showToast({
              notified: newOption === watchingId
            });
            return this._onChangeWrapper(newOption, this.content[newOption]);
          }
        });
        api.modifyClass("component:user-card-contents", {
          pluginId: PLUGIN_ID,
          appEvents: (0, _service.service)(),
          _show() {
            const result = this._super(...arguments);
            if (result === undefined) {
              localStorage.lastAgcAnalyticsRef = "user_avatar";
            }
            return result;
          },
          _showCallback(username) {
            this.appEvents.trigger("enhanced_user_analytics:event", "user_avatar_click", {
              username
            }, {
              clickRef: "user_avatar"
            });
            return this._super(...arguments);
          }
        });
        api.modifyClass("controller:preferences/tracking", {
          pluginId: PLUGIN_ID,
          currentUser: (0, _service.service)(),
          siteSettings: (0, _service.service)(),
          init() {
            this._super(...arguments);
            return this.notificationLevelsForReplying = [{
              name: (0, _discourseI18n.i18n)("notification_levels.normal"),
              value: _notificationLevels.NotificationLevels.REGULAR
            }, {
              name: (0, _discourseI18n.i18n)("topic.notifications.tracking.title"),
              value: _notificationLevels.NotificationLevels.TRACKING
            }];
          }
        });
        api.modifyClass("component:category-notifications-button", {
          pluginId: PLUGIN_ID,
          modifySelection(content) {
            content = this._super(...arguments);
            content.label = content.key === "tracking" ? (0, _discourseI18n.i18n)("agc.unfollow") : (0, _discourseI18n.i18n)("agc.follow");
            return content;
          }
        });
        api.modifyClass("component:tag-notifications-button", {
          pluginId: PLUGIN_ID,
          modifySelection(content) {
            content = this._super(...arguments);
            content.label = content.key === "tracking" ? (0, _discourseI18n.i18n)("agc.unfollow") : (0, _discourseI18n.i18n)("agc.follow");
            return content;
          }
        });
        api.onPageChange(() => {
          let $navBar = (0, _jquery.default)("#navigation-bar");
          if (!$navBar) {
            return;
          }
          $navBar.find("a").each(function () {
            let $this = (0, _jquery.default)(this);
            $this.attr("data-agc-analytics-ref", "nav_topic_list_tab_" + $this.attr("href").replace("/", ""));
          });
        });
      });
    }
  };
});