define("discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage", ["exports", "discourse/lib/get-owner"], function (_exports, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getStageName = _exports.getPartnerName = _exports.getCommunityName = _exports.communityToPartnerMap = _exports.UPSHIFT_COMMUNITY = _exports.SUPPORTED_STAGES = _exports.SUPPORTED_COMMUNITIES = _exports.STAGING_STAGE = _exports.PROD_STAGE = _exports.IGNITE_COMMUNITY = _exports.GAMMA_STAGE = void 0;
  /**
   * Supported communities.
   */
  const IGNITE_COMMUNITY = _exports.IGNITE_COMMUNITY = "ignite";
  const UPSHIFT_COMMUNITY = _exports.UPSHIFT_COMMUNITY = "upshift";
  const SUPPORTED_COMMUNITIES = _exports.SUPPORTED_COMMUNITIES = [IGNITE_COMMUNITY, UPSHIFT_COMMUNITY];

  /**
   * Supported stages.
   */
  const STAGING_STAGE = _exports.STAGING_STAGE = "staging";
  const GAMMA_STAGE = _exports.GAMMA_STAGE = "gamma";
  const PROD_STAGE = _exports.PROD_STAGE = "prod";
  const SUPPORTED_STAGES = _exports.SUPPORTED_STAGES = [STAGING_STAGE, GAMMA_STAGE, PROD_STAGE];
  const getCommunityName = () => {
    const siteSettings = (0, _getOwner.getOwnerWithFallback)(void 0).lookup("service:site-settings");
    const communityName = siteSettings.community_name;
    if (!communityName) {
      throw new Error(`Community Name site setting has not been configured.`);
    }
    if (!SUPPORTED_COMMUNITIES.includes(communityName)) {
      throw new Error(`Community Name [${communityName}] is not supported.`);
    }
    return communityName;
  };

  // This method is not used in the plugin, but is used in the theme.
  _exports.getCommunityName = getCommunityName;
  const getStageName = () => {
    const siteSettings = (0, _getOwner.getOwnerWithFallback)(void 0).lookup("service:site-settings");
    const stageName = siteSettings.stage_name;
    if (!stageName) {
      throw new Error(`Stage Name site setting has not been configured.`);
    }
    if (!SUPPORTED_STAGES.includes(stageName)) {
      throw new Error(`Stage Name [${stageName}] is not supported.`);
    }
    return stageName;
  };
  _exports.getStageName = getStageName;
  const communityToPartnerMap = _exports.communityToPartnerMap = {
    [IGNITE_COMMUNITY]: "DSP",
    [UPSHIFT_COMMUNITY]: "AFP"
  };
  const getPartnerName = () => {
    return communityToPartnerMap[getCommunityName()];
  };
  _exports.getPartnerName = getPartnerName;
});