define("discourse/plugins/discourse-global-communities/discourse/raw-templates/topic-post-badges", ["exports", "discourse/lib/raw-handlebars", "discourse/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "&nbsp;<a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 19
          },
          "end": {
            "line": 4,
            "column": 26
          }
        }
      })) + "' class='badge badge-notification unread-posts' title='" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "agc.topic-list.unread_title", {
        "name": "i18n",
        "hash": {
          "count": "unreadPosts"
        },
        "hashTypes": {
          "count": "PathExpression"
        },
        "hashContexts": {
          "count": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 81
          },
          "end": {
            "line": 4,
            "column": 137
          }
        }
      })) + "'>" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "agc.topic-list.unread", {
        "name": "i18n",
        "hash": {
          "count": "unreadPosts"
        },
        "hashTypes": {
          "count": "PathExpression"
        },
        "hashContexts": {
          "count": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 139
          },
          "end": {
            "line": 4,
            "column": 189
          }
        }
      })) + "</a>";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "&nbsp;<a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 19
          },
          "end": {
            "line": 7,
            "column": 26
          }
        }
      })) + "' class='badge badge-notification new-posts new' title='" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "agc.topic-list.new_title", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 82
          },
          "end": {
            "line": 7,
            "column": 117
          }
        }
      })) + "'>" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "agc.topic-list.new", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 119
          },
          "end": {
            "line": 7,
            "column": 148
          }
        }
      })) + "</a>";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<span class='topic-post-badges'>" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "unreadPosts", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 2
          },
          "end": {
            "line": 5,
            "column": 10
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, "unseen", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 2
          },
          "end": {
            "line": 8,
            "column": 10
          }
        }
      })) != null ? stack1 : "") + "</span>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/topic-post-badges", template, {
    core: false,
    pluginName: "discourse-global-communities",
    hasModernReplacement: true
  });
  var _default = _exports.default = template;
});