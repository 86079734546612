define("discourse/plugins/discourse-global-communities/discourse/initializers/saved-posts", ["exports", "discourse/lib/get-url", "discourse/lib/plugin-api", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/notify-button", "discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/saved-post-button"], function (_exports, _getUrl, _pluginApi, _discourseI18n, _notifyButton, _savedPostButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-saved-posts",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.29.0", api => {
        replaceBookmarkButton(api);
        addNotifyButtonToPostMenu(api);
        api.addQuickAccessProfileItem({
          href: (0, _getUrl.default)("/my/activity/bookmarks"),
          className: "bookmarks-quick-access-item",
          icon: "bookmark-squid",
          content: (0, _discourseI18n.i18n)("agc.navs.saved_items")
        });
      });
    }
  };
  function replaceBookmarkButton(api) {
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          buttonKeys
        }
      } = _ref;
      dag.add("agc-saved-post", _savedPostButton.default, {
        before: buttonKeys.COPY_LINK,
        after: buttonKeys.LIKE
      });
      dag.delete(buttonKeys.BOOKMARK);
    });
  }
  function addNotifyButtonToPostMenu(api) {
    api.registerValueTransformer("post-menu-buttons", _ref2 => {
      let {
        value: dag,
        context: {
          buttonKeys
        }
      } = _ref2;
      dag.add("agc-notify-btn", _notifyButton.default, {
        before: "agc-saved-post",
        after: buttonKeys.LIKE
      });
    });
  }
});