define("discourse/plugins/discourse-global-communities/discourse/feature-flags/mobile-tab-bar", ["exports", "discourse/lib/get-owner", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/mobile-tab-bar"], function (_exports, _getOwner, _discourseI18n, _mobileTabBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcMobileTabBar {
    static enabledKey = "enable_mobile_tab_bar";
    static className = "agc-mobile-tab-bar";
    static afterEnabled(api) {
      const site = api.container.lookup("service:site");
      if (!site.mobileView) {
        return;
      }
      const application = api.container.lookup("controller:application");
      // eslint-disable-next-line ember/no-private-routing-service
      const router = api.container.lookup("router:main");
      const agcHeader = api.container.lookup("service:agc-header");

      // build tabs
      const tabs = agcHeader.splitUserMenuAndNotificationsEnabled ? getDefaultTabs() : getTabsFromSettings();
      const user = api.getCurrentUser();
      tabs.forEach(tab => {
        if (tab.destination.includes("/") || tab.destination.includes("drop-")) {
          return;
        }

        // we need this to highlight tab when you navigate to
        // a subroute of a tab's route
        const route = api.container.lookup(`route:${tab.destination}`);
        if (route) {
          route.reopen({
            actions: {
              didTransition() {
                // check if the route has `username` dynamic segment. If it does then
                // highlight only if the target username === currentUser.username
                // if it doesn't have a `username` segment then highlight
                // eslint-disable-next-line ember/no-private-routing-service
                const usernameParam = router._routerMicrolib.recognizer.names[tab.destination].handlers.some(handler => handler.names.some(n => n === "username"));
                if (usernameParam) {
                  const target = this.modelFor("user");
                  if (target && target.username === user.username) {
                    (0, _mobileTabBar.highlight)(tab.destination);
                  }
                } else {
                  (0, _mobileTabBar.highlight)(tab.destination);
                }
                this._super(...arguments);
                return true;
              }
            }
          });
        }
      });
      application.set("agcMobileTabs", tabs);
    }
  }

  // When the notifications/menu are split into two separate menus the mobile tab includes the notifications menu.
  // In this case we will ignore the settings and provide a default set of tabs
  _exports.default = AgcMobileTabBar;
  function getDefaultTabs() {
    const circlesService = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:circles");
    const programPillarsService = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:agc-program-pillars");
    const userCanUseCircles = circlesService?.userCanUseCircles;
    const tvTab = {
      title: (0, _discourseI18n.i18n)("agc.navs.tv"),
      icon: "upshift-tv",
      destination: "/tv"
    };
    const programPillarsTab = {
      title: (0, _discourseI18n.i18n)("agc.navs.program_pillars"),
      icon: "megaphone-icon",
      destination: "/program-pillars",
      className: "program-pillars",
      showIndicator: true
    };
    return [{
      title: (0, _discourseI18n.i18n)(`agc.navs.home`),
      icon: "upshift-home",
      destination: "/"
    }, programPillarsService.hasAny ? programPillarsTab : tvTab, {
      title: (0, _discourseI18n.i18n)("agc.navs.post"),
      icon: "far-square-plus",
      destination: "new-topic"
    }, userCanUseCircles ? {
      title: (0, _discourseI18n.i18n)("agc.connect.circles"),
      icon: "discourse_circles",
      destination: "connect"
    } : {
      title: (0, _discourseI18n.i18n)("agc.connect.members"),
      icon: "members-squid",
      destination: "members"
    }, {
      title: (0, _discourseI18n.i18n)("user.notifications"),
      icon: "bell-squid",
      destination: "toggleNotificationsMenu",
      showNotificationsBadge: true
    }];
  }
  function getTabsFromSettings() {
    const circlesService = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:circles");
    const siteSettings = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site-settings");
    const tabs = [];
    [siteSettings.mobile_tab_bar_tab_1_settings, siteSettings.mobile_tab_bar_tab_2_settings, siteSettings.mobile_tab_bar_tab_3_settings, siteSettings.mobile_tab_bar_tab_4_settings, siteSettings.mobile_tab_bar_tab_5_settings, siteSettings.mobile_tab_bar_tab_6_settings].forEach((setting, index) => {
      if (setting === "discourse_circles") {
        const userCanUseCircles = circlesService?.userCanUseCircles;
        setting = userCanUseCircles ? `${(0, _discourseI18n.i18n)("agc.connect.circles")},discourse_circles,connect` : `${(0, _discourseI18n.i18n)("agc.connect.members")},members-squid,members`;
      }
      const props = setting.split(",").map(s => s.trim());
      const last = props[props.length - 1];
      if (last === "false") {
        return;
      } else if (last === "true") {
        props.pop();
      }
      const tabInfo = props.length === 2 ? {
        title: (0, _discourseI18n.i18n)(`agc.mobile_tab_bar.tab${index + 1}_title`),
        icon: props[0],
        destination: props[1]
      } : {
        title: props[0],
        icon: props[1],
        destination: props[2]
      };
      tabs.push({
        ...tabInfo,
        showUnreadPMBadge: tabInfo.destination === "userPrivateMessages"
      });
    });
    return tabs;
  }
});