define("discourse/plugins/discourse-global-communities/discourse/initializers/comments", ["exports", "virtual-dom", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _virtualDom, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "comments",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        api.decorateWidget("post-links:after", helper => {
          const attrs = helper.attrs;
          const tv = attrs.is_tv || attrs.is_tv_episode;
          const topic = attrs.topic || container.lookup("controller:topic").model;
          if (attrs.firstPost && !topic.isPrivateMessage) {
            const containerClasses = ["comments-container"];
            if (helper.attrs.links?.length || helper.attrs.topic?.agc_merged_topics?.length) {
              containerClasses.push("links-present");
            }
            return [(0, _virtualDom.h)(`#comments-container.${containerClasses.join(".")}`, [(0, _virtualDom.h)("h4.comments", (0, _discourseI18n.i18n)("agc.comments.title")), helper.attach("amazon-comments-info", {
              topic
            }), attrs.canCreatePost ? helper.attach("comment-prompt", {
              tv
            }) : "", (0, _virtualDom.h)("div.comments-filter", helper.attach("comments-filter", {
              topic
            }))])];
          }
        });
        api.decorateWidget("posts-filtered-notice:before", helper => {
          const topic = container.lookup("controller:topic").model;
          return helper.attach("see-amazon-comments-button", {
            topic
          });
        });
      });
    }
  };
});