define("discourse/plugins/discourse-global-communities/discourse/components/gc-location-picker-group", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "discourse/helpers/d-icon", "discourse/lib/decorators", "discourse/plugins/discourse-global-communities/discourse/components/gc-location-picker-filter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _dIcon, _decorators, _gcLocationPickerFilter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GcLocationPickerGroup extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked], function () {
      return false;
    }))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleExpanded", [_decorators.bind]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="location-picker-group">
          <div role="button" class="label" {{on "click" this.toggleExpanded}}>
            {{@group.label}}
    
            {{#if @group.isExpanded}}
              {{icon "caret-up" class="caret-icon"}}
            {{else}}
              {{icon "caret-down" class="caret-icon"}}
            {{/if}}
          </div>
    
          {{#if this.expanded}}
            {{#each @group.filters as |filter|}}
              <GcLocationPickerFilter @filter={{filter}} @onCheck={{@onCheck}} />
            {{/each}}
          {{/if}}
        </div>
      
    */
    {
      "id": "olAayFpz",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"location-picker-group\"],[12],[1,\"\\n      \"],[11,0],[24,\"role\",\"button\"],[24,0,\"label\"],[4,[32,0],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n\\n\"],[41,[30,1,[\"isExpanded\"]],[[[1,\"          \"],[1,[28,[32,1],[\"caret-up\"],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,1],[\"caret-down\"],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"expanded\"]],[[[42,[28,[31,2],[[28,[31,2],[[30,1,[\"filters\"]]],null]],null],null,[[[1,\"          \"],[8,[32,2],null,[[\"@filter\",\"@onCheck\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@group\",\"filter\",\"@onCheck\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-location-picker-group.js",
      "scope": () => [_modifier.on, _dIcon.default, _gcLocationPickerFilter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GcLocationPickerGroup;
});