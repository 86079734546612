define("discourse/plugins/discourse-global-communities/discourse/components/agc-merged-topic-post-links", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse/helpers/replace-emoji", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _replaceEmoji, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcMergedTopicPostLinks extends _component.default {
    get hasMergedTopics() {
      return this.args.mergedTopics?.length;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.hasMergedTopics}}
          <h3 class="post-links-header">
            {{i18n "discourse_gc.post_links.merged"}}
          </h3>
    
          <ul class="post-links merged-post-links">
            {{#each @mergedTopics as |mergedTopic|}}
              <li>
                <a class="track-link inbound" href={{mergedTopic.old_topic_url}}>
                  {{dIcon "link"}}
                  <span>{{replaceEmoji mergedTopic.old_topic_title}}</span>
                </a>
              </li>
            {{/each}}
          </ul>
        {{/if}}
    
        {{#if @hasOtherLinks}}
          <h3 class="post-links-header">
            {{i18n "discourse_gc.post_links.mentioned"}}
          </h3>
        {{/if}}
      
    */
    {
      "id": "WPsQ681r",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"hasMergedTopics\"]],[[[1,\"      \"],[10,\"h3\"],[14,0,\"post-links-header\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_gc.post_links.merged\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,\"ul\"],[14,0,\"post-links merged-post-links\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n            \"],[10,3],[14,0,\"track-link inbound\"],[15,6,[30,2,[\"old_topic_url\"]]],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"link\"],null]],[1,\"\\n              \"],[10,1],[12],[1,[28,[32,2],[[30,2,[\"old_topic_title\"]]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,\"h3\"],[14,0,\"post-links-header\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_gc.post_links.mentioned\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@mergedTopics\",\"mergedTopic\",\"@hasOtherLinks\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-merged-topic-post-links.js",
      "scope": () => [_discourseI18n.i18n, _dIcon.default, _replaceEmoji.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcMergedTopicPostLinks;
});