define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-related-suggested-topics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcRelatedSuggestedTopicsFeatureFlag {
    static enabledKey = "agc_related_suggested_topics_enabled";
    static afterEnabled(api) {
      const agcAnalyticsApi = api.agcAnalytics;
      if (agcAnalyticsApi) {
        // Add listeners for related & suggested topics, and log clicks on the main links
        const selectors = ["#related-topics", "#suggested-topics"];
        selectors.forEach(selector => {
          agcAnalyticsApi.registerClickAnalytics(`${selector} .topic-list-item .top-row a`, {
            eventType: "agc-custom:related-topic-clicked",
            eventData: element => {
              try {
                const listItem = element.closest(".topic-list-item");
                const topicList = element.closest(".topic-list-body");
                let listItemIndex;
                for (let i = 0; i < topicList.children.length; i++) {
                  if (topicList.children[i] === listItem) {
                    listItemIndex = i;
                  }
                }
                return {
                  position: listItemIndex,
                  topic_id: listItem.dataset.topicId,
                  selector
                };
              } catch (error) {
                return {
                  error: error.toString()
                };
              }
            }
          });
        });
      }
    }
  }
  _exports.default = AgcRelatedSuggestedTopicsFeatureFlag;
});