define("discourse/plugins/discourse-global-communities/discourse/feature-flags/improved-search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ImprovedSearchResultsFeatureFlag {
    static enabledKey = "enable_improved_search";
    static className = "improved_search_enabled";
    static afterEnabled(api) {
      const agcImprovedSearchService = api.container.lookup("service:agc-improved-search");
      const searchPreferencesManager = api.container.lookup("service:search-preferences-manager");

      // On load, set sort order to 0 rather than getting it from the cache
      searchPreferencesManager.sortOrder = 0;
      api.registerBehaviorTransformer("full-page-search-load-more", _ref => {
        let {
          next
        } = _ref;
        if (!agcImprovedSearchService.loadMorePressed) {
          return;
        }
        next();
      });
    }
  }
  _exports.default = ImprovedSearchResultsFeatureFlag;
});