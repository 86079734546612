define("discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-categories", ["exports", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @categoryList.length}}
      <div class="homepage-hero-cell categories-sidebar">
        <h3 class="cell-title">
          {{i18n "agc.homepage.sidebar.categories.headline"}}
        </h3>
        {{#each @categoryList as |c|}}
          <div>
            <div class="category">
              <h3>
                <a
                  class="category-title-link"
                  href={{c.url}}
                  data-agc-analytics-ref="nav_sidebar_explore_{{c.slug}}"
                >
                  <div class="category-text-title">{{c.name}}</div>
                </a>
              </h3>
            </div>
          </div>
        {{/each}}
      </div>
    {{/if}}
  
  */
  {
    "id": "TjYhP6Ws",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"homepage-hero-cell categories-sidebar\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"agc.homepage.sidebar.categories.headline\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[12],[1,\"\\n          \"],[10,0],[14,0,\"category\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,\"\\n              \"],[10,3],[14,0,\"category-title-link\"],[15,6,[30,2,[\"url\"]]],[15,\"data-agc-analytics-ref\",[29,[\"nav_sidebar_explore_\",[30,2,[\"slug\"]]]]],[12],[1,\"\\n                \"],[10,0],[14,0,\"category-text-title\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"@categoryList\",\"c\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-categories.js",
    "scope": () => [_discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "gc-sidebar-categories"));
});