define("discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-featured-members", ["exports", "discourse/helpers/avatar", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _avatar, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="homepage-hero-secondary homepage-hero-cell">
      <h3 class="cell-title">{{i18n "agc.homepage.hero.secondary.headline"}}</h3>
      <div class="secondary-featured-users">
        {{#each @featuredUsers as |fu|}}
          <a
            href="/u/{{fu.user.username}}"
            data-agc-analytics-ref="nav_sidebar_member_avatar_{{fu.user.username}}"
            class="avatar-link"
          >
            {{avatar fu.user imageSize="large"}}
          </a>
        {{/each}}
      </div>
      <a
        class="btn btn-default"
        data-agc-analytics-ref="nav_sidebar_all_members"
        href="/members"
      >{{i18n "agc.homepage.hero.secondary.button"}} ({{@totalUsers}})</a>
    </div>
  
  */
  {
    "id": "lzbGBEk6",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"homepage-hero-secondary homepage-hero-cell\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,[28,[32,0],[\"agc.homepage.hero.secondary.headline\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"secondary-featured-users\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"        \"],[10,3],[15,6,[29,[\"/u/\",[30,2,[\"user\",\"username\"]]]]],[15,\"data-agc-analytics-ref\",[29,[\"nav_sidebar_member_avatar_\",[30,2,[\"user\",\"username\"]]]]],[14,0,\"avatar-link\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,2,[\"user\"]]],[[\"imageSize\"],[\"large\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,3],[14,0,\"btn btn-default\"],[14,\"data-agc-analytics-ref\",\"nav_sidebar_all_members\"],[14,6,\"/members\"],[12],[1,[28,[32,0],[\"agc.homepage.hero.secondary.button\"],null]],[1,\" (\"],[1,[30,3]],[1,\")\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@featuredUsers\",\"fu\",\"@totalUsers\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-featured-members.js",
    "scope": () => [_discourseI18n.i18n, _avatar.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "gc-sidebar-featured-members"));
});