define("discourse/plugins/discourse-global-communities/discourse/initializers/discourse-global-communities", ["exports", "discourse/lib/avatar-flair", "discourse/lib/icon-library", "discourse/lib/plugin-api", "discourse/lib/url", "discourse/widgets/post", "discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/excluded-from-recommendations-button", "discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/helpful-button"], function (_exports, _avatarFlair, _iconLibrary, _pluginApi, _url, _post, _excludedFromRecommendationsButton, _helpfulButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api) {
    customizePostMenu(api);
    api.modifyClass("controller:preferences/account", {
      pluginId: "discourse-global-communities",
      canEditName: false
    });
    api.modifyClass("component:user-card-contents", {
      pluginId: "discourse-global-communities",
      _show(_, target) {
        if (!this.siteSettings.enable_user_cards) {
          _url.default.routeTo(`${target.href}/summary`);
          return false;
        }
        this._super(...arguments);
      }
    });
    api.addBulkActionButton({
      id: "toggle-staged",
      label: "discourse_gc.toggle_staged",
      icon: "user-xmark",
      class: "btn-default",
      action: _ref => {
        let {
          performAndRefresh
        } = _ref;
        performAndRefresh({
          type: "toggle_staged"
        });
      },
      actionType: "performAndRefresh"
    });
    api.includePostAttributes("show_country_flair");
    api.includePostAttributes("primary_group_flair_url");
    api.decorateWidget("poster-name:after", dec => {
      if (dec.attrs.show_country_flair === true) {
        return dec.h("span.poster-icon", {}, dec.h("img", {
          attributes: {
            src: dec.attrs.primary_group_flair_url,
            class: "country-flair"
          }
        }));
      }
    });
    api.reopenWidget("post-avatar", {
      // This was copied from discourse core
      // The only change is not showing the avatar flair unless show_country_flair === null
      html(attrs) {
        let body;
        if (!attrs.user_id) {
          body = (0, _iconLibrary.iconNode)("far-trash-can", {
            class: "deleted-user-avatar"
          });
        } else {
          body = _post.avatarFor.call(this, this.settings.size, {
            template: attrs.avatar_template,
            username: attrs.username,
            name: attrs.name,
            url: attrs.usernameUrl,
            className: "main-avatar",
            hideTitle: true
          });
        }
        const result = [body];
        if (attrs.primary_group_flair_url || attrs.primary_group_flair_bg_color) {
          if (attrs.show_country_flair === null) {
            result.push(this.attach("avatar-flair", attrs));
          }
        } else {
          const autoFlairAttrs = (0, _avatarFlair.default)(this.site, attrs);
          if (autoFlairAttrs) {
            result.push(this.attach("avatar-flair", autoFlairAttrs));
          }
        }
        result.push(api.h("div.poster-avatar-extra"));
        if (this.settings.displayPosterName) {
          result.push(this.attach("post-avatar-user-info", attrs));
        }
        return result;
      }
    });
  }
  function customizePostMenu(api) {
    const trackedProperties = ["can_mark_helpful", "is_helpful", "can_mark_excluded_from_recommendations", "is_excluded_from_recommendations", "post_tags"];
    api.addTrackedPostProperties(...trackedProperties);
    api.includePostAttributes(...trackedProperties);
    api.registerValueTransformer("post-menu-buttons", _ref2 => {
      let {
        value: dag,
        context: {
          firstButtonKey
        }
      } = _ref2;
      dag.add("agc-exclude", _excludedFromRecommendationsButton.default, {
        before: firstButtonKey
      });
      dag.add("agc-helpful", _helpfulButton.default, {
        before: firstButtonKey
      });
    });
  }
  var _default = _exports.default = {
    name: "discourse-global-communities",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.39.0", initialize);
    }
  };
});