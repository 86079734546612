define("discourse/plugins/discourse-global-communities/discourse/components/agc-merged-post-footer", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse/helpers/replace-emoji", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _replaceEmoji, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcMergedPostFooter extends _component.default {
    get text() {
      const fullOrPartial = this.args.fullMove ? "full" : "partial";
      const multipleOrSingle = this.args.multiple ? "multiple" : "single";
      return (0, _discourseI18n.i18n)(`discourse_gc.merged_post.consolidate_reasons.${fullOrPartial}_move_${multipleOrSingle}`, {
        who: this.args.who,
        date: this.args.date
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="__text">{{this.text}}</div>
        <div class="__link">
          <a class="track-link inbound" href={{@url}}>
            {{dIcon "link"}}
            <span>{{replaceEmoji @title}}</span>
          </a>
        </div>
      
    */
    {
      "id": "53Jb4F2i",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"__text\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"__link\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"track-link inbound\"],[15,6,[30,1]],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"link\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,1],[[30,2]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@url\",\"@title\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-merged-post-footer.js",
      "scope": () => [_dIcon.default, _replaceEmoji.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcMergedPostFooter;
});